import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import {
  // ActivityList,
  ActivityType,
  AssetCategory,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { ContactType } from '../../sj-orm/models/contact.dto';

export type LegalEntity = LegalEntityFormPart1Type &
  LegalEntityFormPart2Type &
  AssetsOwnershipPartType &
  AssetsStatisticsPartType;

export enum FormOfOrganizationType {
  public = 'public',
  individual = 'individual',
}

const FormOfOrganizationList = [
  {
    label: 'forms_assets:legalEntity.enums.formOfOrganization.public',
    key: FormOfOrganizationType.public,
  },
  {
    label: 'forms_assets:legalEntity.enums.formOfOrganization.private',
    key: FormOfOrganizationType.individual,
  },
];

export enum LegalEntityUsageProfileType {
  personal = 'personal',
  commercial = 'commercial',
  investment = 'investment',
  strategic = 'strategic',
}

const LegalEntityUsageProfileList = [
  {
    key: LegalEntityUsageProfileType.personal,
    label: 'forms_assets:legalEntity.enums.usageProfile.personal',
  },
  {
    key: LegalEntityUsageProfileType.commercial,
    label: 'forms_assets:legalEntity.enums.usageProfile.commercial',
  },
  {
    key: LegalEntityUsageProfileType.investment,
    label: 'forms_assets:legalEntity.enums.usageProfile.investment',
  },
  {
    key: LegalEntityUsageProfileType.strategic,
    label: 'forms_assets:legalEntity.enums.usageProfile.strategic',
  },
];

export enum TaxAgentType {
  company = 'company',
  person = 'person',
  other = 'other',
}

const TaxAgentList = [
  {
    key: TaxAgentType.company,
    label: 'forms_assets:legalEntity.enums.taxAgent.company',
  },
  {
    key: TaxAgentType.person,
    label: 'forms_assets:legalEntity.enums.taxAgent.person',
  },
  {
    key: TaxAgentType.other,
    label: 'forms_assets:legalEntity.enums.taxAgent.other',
  },
];

type LegalEntityPart1 = Omit<BaseDto, 'id'> & {
  nickname: string;
  country: string;
  entityType?: ActivityType;
  passiveType?: FormLabelItem;
  purchaseValue: number;
  legalName: string;
};

type LegalEntityPart2 = Omit<BaseDto, 'id'> & {
  formOfOrganization?: string;
  structure?: string;
  typeOfBusiness?: string;
  portfolioCurrency?: string;
  plannedDividends?: number;
  actualDividends?: number;
  dividendsDelta?: number;
  myAssets: DtoId[];
  dividendTaxAgent?: TaxAgentType;
  otherDividendTaxAgent?: string;
  longTermShareholderLiabilities?: QuestionType.NUMBER;
  longTermCompanyLiabilities?: QuestionType.NUMBER;
  isCompanyHasLimitations?: boolean;
  companyLimitationsComment?: string;
  isShareholderHasLimitations?: boolean;
  shareholderLimitationsComment?: string;
  usageProfile?: LegalEntityUsageProfileType;
  dateOfIncorporation?: number;
  taxNumber?: string;
  taxNumberIssuedBy?: string;
  lei?: string;
  legalEntityAddress?: FormItemLabelType;
  province?: string;
  zipCode?: string;
  city?: string;
  street?: string;
  buildingNumber?: string;
  apartment?: string;
  addressComment?: string;
  contacts?: DtoId[];
  annualReports?: DtoId[];
  assetInfoDocuments?: DtoId[];
  powers?: DtoId[];
  powersComments?: string;
};

type LegalEntityHelpPart1 = {
  nickname: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  entityType: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  legalName: QuestionType.TEXT;
};

type LegalEntityHelpPart2 = {
  formOfOrganization?: QuestionType.SELECT_FROM_LIST;
  structure?: QuestionType.TEXT;
  typeOfBusiness?: QuestionType.TEXT;
  plannedDividends?: QuestionType.CURRENCY_CALCULATOR;
  actualDividends?: QuestionType.CURRENCY_CALCULATOR;
  dividendsDelta?: QuestionType.NUMBER;
  myAssets: QuestionType.PICK_ARRAY_OF_DTO;
  dividendTaxAgent?: QuestionType.SELECT_FROM_LIST;
  otherDividendTaxAgent?: QuestionType.TEXT;
  longTermShareholderLiabilities?: QuestionType.NUMBER;
  longTermCompanyLiabilities?: QuestionType.NUMBER;
  isCompanyHasLimitations?: QuestionType.CHECKBOX;
  companyLimitationsComment?: QuestionType.TEXT_MULTILINE;
  isShareholderHasLimitations?: QuestionType.CHECKBOX;
  shareholderLimitationsComment?: QuestionType.TEXT_MULTILINE;
  usageProfile?: QuestionType.SELECT_FROM_LIST;
  dateOfIncorporation?: QuestionType.DATE_TIME;
  taxNumber?: QuestionType.TEXT;
  taxNumberIssuedBy?: QuestionType.TEXT;
  lei?: QuestionType.TEXT;
  province?: QuestionType.TEXT;
  zipCode?: QuestionType.TEXT;
  city?: QuestionType.TEXT;
  street?: QuestionType.TEXT;
  buildingNumber?: QuestionType.TEXT;
  apartment?: QuestionType.TEXT;
  addressComment?: QuestionType.TEXT_MULTILINE;
  contacts?: QuestionType.PICK_ARRAY_OF_DTO;
  annualReports?: QuestionType.PICK_ARRAY_OF_DTO;
  assetInfoDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  powers?: QuestionType.PICK_ARRAY_OF_DTO;
  powersComments?: QuestionType.TEXT_MULTILINE;
};

type LegalEntityFormType = [
  LegalEntityFormPart1Type,
  LegalEntityFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type LegalEntityFormPart1Type = TDtoWrapper<
  LegalEntityPart1,
  LegalEntityHelpPart1
>;

type LegalEntityFormPart2Type = TDtoWrapper<
  LegalEntityPart2,
  LegalEntityHelpPart2
>;

const LegalEntityFormPart1: LegalEntityFormPart1Type = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  // entityType: {
  //   type: QuestionType.SELECT_FROM_LIST,
  //   label: 'forms_assets:legalEntity.inputs.type.label',
  //   value: ActivityType.active,
  //   required: true,
  //   items: ActivityList,
  // },
  passiveType: {
    type: FormLabelItem.text,
    label: 'forms_assets:legalEntity.inputs.passiveType.label',
    showIf: {
      entityType: {
        value: ActivityType.passive,
      },
    },
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:legalEntity.inputs.capitalization.label',
    tooltip: 'forms_assets:legalEntity.inputs.capitalization.tooltip',
    equivalentValueTooltip:
      // eslint-disable-next-line sonarjs/no-duplicate-string
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  legalName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:legalEntity.inputs.legalName.label',
    value: '',
    // required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:legalEntity.inputs.legalName.errors.required'),
  },
};

const LegalEntityFormPart2: LegalEntityFormPart2Type = {
  plannedDividends: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:legalEntity.inputs.expectedDividends.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  actualDividends: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:legalEntity.inputs.actualDividends.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 2,
  },
  dividendsDelta: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:legalEntity.inputs.dividendsDelta.label',
    tooltip: 'forms_assets:legalEntity.inputs.dividendsDelta.tooltip',
    order: 3,
  },
  myAssets: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:legalEntity.inputs.myAssets.label',
    tooltip: 'forms_assets:legalEntity.inputs.myAssets.tooltip',
    items: 'assets',
    mode: 'multiple',
    dtoType: AssetCategory.LAND,
    order: 3,
  },
  formOfOrganization: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:legalEntity.inputs.formOfOrganization.label',
    items: FormOfOrganizationList,
    order: 4,
  },
  structure: {
    type: QuestionType.TEXT,
    label: 'forms_assets:legalEntity.inputs.structure.label',
    order: 5,
  },
  typeOfBusiness: {
    type: QuestionType.TEXT,
    label: 'forms_assets:legalEntity.inputs.typeOfBusiness.label',
    order: 6,
  },
  dividendTaxAgent: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:legalEntity.inputs.dividendTaxAgent.label',
    items: TaxAgentList,
    order: 7,
  },
  otherDividendTaxAgent: {
    type: QuestionType.TEXT,
    label: 'forms_assets:legalEntity.inputs.otherDividendTaxAgent.label',
    showIf: {
      dividendTaxAgent: {
        value: TaxAgentType.other,
      },
    },
    order: 8,
  },
  longTermShareholderLiabilities: {
    type: QuestionType.NUMBER,
    label:
      'forms_assets:legalEntity.inputs.longTermShareholderLiabilities.label',
    order: 9,
  },
  longTermCompanyLiabilities: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:legalEntity.inputs.longTermCompanyLiabilities.label',
    order: 10,
  },
  isCompanyHasLimitations: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:legalEntity.inputs.isCompanyHasLimitations.label',
    order: 11,
  },
  companyLimitationsComment: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:legalEntity.inputs.companyLimitationsComment.label',
    showIf: {
      isCompanyHasLimitations: {
        value: true,
      },
    },
    order: 12,
  },
  isShareholderHasLimitations: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:legalEntity.inputs.isShareholderHasLimitations.label',
    order: 13,
  },
  shareholderLimitationsComment: {
    type: QuestionType.TEXT_MULTILINE,
    label:
      'forms_assets:legalEntity.inputs.shareholderLimitationsComment.label',
    showIf: {
      isShareholderHasLimitations: {
        value: true,
      },
    },
    order: 14,
  },
  usageProfile: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:legalEntity.inputs.usageProfile.label',
    items: LegalEntityUsageProfileList,
    order: 15,
  },
  dateOfIncorporation: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:legalEntity.inputs.dateOfIncorporation.label',
    tooltip: 'forms_assets:legalEntity.inputs.dateOfIncorporation.tooltip',
    order: 16,
  },
  taxNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:legalEntity.inputs.taxNumber.label',
    order: 17,
  },
  taxNumberIssuedBy: {
    type: QuestionType.TEXT,
    label: 'forms_assets:legalEntity.inputs.taxNumberIssuedBy.label',
    order: 18,
  },
  lei: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.lei.label',
    tooltip: 'forms_assets:common.inputs.lei.tooltip',
    order: 19,
  },
  legalEntityAddress: {
    type: FormLabelItem.label,
    label: 'forms_assets:common.inputs.legalEntityAddress.label',
    order: 20,
  },
  province: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.province.label',
    order: 21,
  },
  zipCode: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.zipCode.label',
    order: 22,
  },
  city: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.city.label',
    order: 23,
  },
  street: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.street.label',
    order: 24,
  },
  buildingNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.buildingNumber.label',
    order: 25,
  },
  apartment: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.apartment.label',
    order: 26,
  },
  addressComment: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
    order: 27,
  },
  contacts: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:legalEntity.inputs.contacts.label',
    items: 'contacts',
    mode: 'multiple',
    dtoType: ContactType.BANK,
    order: 28,
  },
  annualReports: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:legalEntity.inputs.annualReports.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  assetInfoDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    items: 'privateDocuments',
    mode: 'multiple',
    label: 'forms_assets:stocks.inputs.assetInfoDocuments.label',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  powers: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  powersComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.powersOfAttorneyComments.label',
  },
};

const LegalEntityForm: LegalEntityFormType = [
  LegalEntityFormPart1,
  LegalEntityFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgentWithBrokerOther',
    'limitations',
    'limitationsComments',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { LegalEntityForm };
