import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { AssetCategory } from '../../sj-orm/models/asset.dto';
import { ContactType } from '../../sj-orm/models/contact.dto';

export type Land = LandFormPart1Type &
  LandFormPart2Type &
  AssetsOwnershipPartType &
  AssetsStatisticsPartType;

export enum PermittedUseType {
  recreational = 'recreational',
  sanitary = 'sanitary',
}

const PermittedUseList = [
  {
    label: 'forms_assets:land.enums.useType.recreational',
    key: PermittedUseType.recreational,
  },
  {
    label: 'forms_assets:land.enums.useType.sanitary',
    key: PermittedUseType.sanitary,
  },
];

type LandPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  purchaseValue: number;
  district: string;
  addressLabel?: string;
  index: string;
  city: string;
  street: string;
  buildingNumber: string;
};

type LandPart2 = Omit<BaseDto, 'id'> & {
  numberName?: string;
  number?: number;
  // otherAddressInfo?: string;
  commencementDate?: string;
  landArea?: number;
  permittedUse?: PermittedUseType;
  compositionOfObjects?: string;
  buildingInformationOnTheLandPlot?: boolean;
  chooseBuilding?: DtoId[];
  contacts?: DtoId[];
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type LandHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  district: QuestionType.TEXT;
  index: QuestionType.TEXT;
  city: QuestionType.TEXT;
  street: QuestionType.TEXT;
  buildingNumber: QuestionType.TEXT;
};

type LandHelpPart2 = {
  numberName?: QuestionType.TEXT;
  number?: QuestionType.NUMBER;
  // otherAddressInfo?: QuestionType.TEXT_MULTILINE;
  commencementDate?: QuestionType.DATE_TIME;
  landArea?: QuestionType.NUMBER;
  permittedUse?: QuestionType.SELECT_FROM_LIST;
  compositionOfObjects?: QuestionType.TEXT;
  buildingInformationOnTheLandPlot?: QuestionType.CHECKBOX;
  chooseBuilding?: QuestionType.PICK_ARRAY_OF_DTO;
  contacts?: QuestionType.PICK_ARRAY_OF_DTO;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type LandFormType = [
  LandFormPart1Type,
  LandFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type LandFormPart1Type = TDtoWrapper<LandPart1, LandHelpPart1>;
type LandFormPart2Type = TDtoWrapper<LandPart2, LandHelpPart2>;

const LandFormPart1: LandFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:land.inputs.purchaseValue.label',
    tooltip: 'forms_assets:land.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  addressLabel: {
    type: FormLabelItem.label,
    label: 'forms_assets:common.inputs.addressLabel.label',
    tooltip: 'forms_assets:common.inputs.addressLabel.tooltip',
  },
  district: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:land.inputs.district.label',
    },
    // required: true,
    value: '',
  },
  index: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.zipCode.label',
    },
    // required: true,
    value: '',
  },
  city: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.city.label',
    },
    // required: true,
    value: '',
  },
  street: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.street.label',
    },
    // required: true,
    value: '',
  },
  buildingNumber: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:land.inputs.plotOrBuildingNumber.label',
    },
    // required: true,
    value: '',
  },
};

const LandFormPart2: LandFormPart2Type = {
  numberName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:land.inputs.numberName.label',
    order: 1,
  },
  number: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:land.inputs.number.label',
    order: 2,
  },
  // otherAddressInfo: {
  //   type: QuestionType.TEXT_MULTILINE,
  //   label: 'forms_assets:land.inputs.otherAddressInfo.label',
  // },
  commencementDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:land.inputs.commencementDate.label',
    tooltip: 'forms_assets:land.inputs.commencementDate.tooltip',
    order: 3,
  },
  permittedUse: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:land.inputs.permittedUse.label',
    items: PermittedUseList,
    order: 4,
  },
  compositionOfObjects: {
    type: QuestionType.TEXT,
    label: 'forms_assets:land.inputs.compositionOfObjects.label',
    order: 5,
  },
  landArea: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:land.inputs.landArea.label',
    order: 6,
  },
  buildingInformationOnTheLandPlot: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:land.inputs.buildingInformationOnTheLandPlot.label',
    order: 7,
  },
  chooseBuilding: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:land.inputs.chooseBuilding.label',
    items: 'assets',
    mode: 'multiple',
    showIf: {
      buildingInformationOnTheLandPlot: {
        value: true,
      },
    },
    dtoType: AssetCategory.LAND,
    order: 8,
  },
  contacts: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.contacts.label',
    items: 'contacts',
    mode: 'multiple',
    order: 9,
    dtoType: ContactType.BANK,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const LandForm: LandFormType = [
  LandFormPart1,
  LandFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgent',
    'publicFundTaxAgent',
    'taxAgentOther',
    'taxAgentWithBrokerOther',
    'publicTaxAgentOther',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { LandForm };
