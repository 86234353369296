import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAssets } from '@/entities/assets/hooks/use-assets';
import { TBeneficiariesSizes } from '@/entities/next-owner/hooks/use-beneficiary/types';
import {
  AccountStatus,
  ScenarioStatus,
  useGetBeneficiariesLazyQuery,
  useScenariosLazyQuery,
} from '@/shared/generated/graphql';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import {
  BeneficiaryDto,
  BeneficiaryGroup,
} from '@/shared/lib/sj-orm/models/beneficiary.dto';
import { DelegatingRequestStatus } from '@/shared/lib/sj-orm/models/delegating-request.dto';

export const useBeneficiary = (props?: { delay: number }) => {
  const [getScenarios, { data: dataScenarios, loading: scenariosLoading }] =
    useScenariosLazyQuery();
  const { getAsset } = useAssets(props);
  const [getCentralDbBeneficiaries] = useGetBeneficiariesLazyQuery();
  const store = useSJDatabase();
  const [sizes, setSizes] = useState<TBeneficiariesSizes>({
    all: 0,
    [BeneficiaryGroup.CIRCLE]: 0,
    [BeneficiaryGroup.FAMILY_CLAN]: 0,
    [BeneficiaryGroup.FAMILY]: 0,
  });

  useEffect(() => {
    const clanLength =
      store.collections.beneficiaries?.findMany((beneficiary) => {
        return beneficiary.group === BeneficiaryGroup.FAMILY_CLAN;
      })?.length || 0;
    const circleLength =
      store.collections.beneficiaries?.findMany(
        (beneficiary) => beneficiary.group === BeneficiaryGroup.CIRCLE,
      )?.length || 0;
    const familyLength =
      store.collections.beneficiaries?.findMany(
        (beneficiary) => beneficiary.group === BeneficiaryGroup.FAMILY,
      )?.length || 0;

    setSizes({
      [BeneficiaryGroup.FAMILY_CLAN]: clanLength,
      [BeneficiaryGroup.FAMILY]: familyLength,
      [BeneficiaryGroup.CIRCLE]: circleLength,
      all: store.collections.beneficiaries?.size() || 0,
    });
    setTimeout(() => getScenarios(), props?.delay || 0);
  }, [store, getScenarios]);

  const getBeneficiariesList = useCallback(
    (
      group: BeneficiaryGroup | 'all',
      sort?: (first: BeneficiaryDto, second: BeneficiaryDto) => number,
      filters?: {
        countries: Array<string>;
        groups: Array<BeneficiaryGroup>;
      },
    ): BeneficiaryDto[] => {
      const result: BeneficiaryDto[] = [];
      const groups =
        group === 'all'
          ? filters?.groups?.length
            ? [...filters.groups]
            : [
                BeneficiaryGroup.FAMILY,
                BeneficiaryGroup.FAMILY_CLAN,
                BeneficiaryGroup.CIRCLE,
              ]
          : [group];
      for (const group_ of groups) {
        const items_ = store.collections.beneficiaries?.findMany(
          (beneficiary) =>
            filters?.countries?.length
              ? filters.countries.includes(beneficiary.linkedCountryCode)
              : true,
          sort ? sort : undefined,
        );

        const items = items_?.filter((el) => el.group === group_);

        result.push(...(items || []));
      }
      return Array.from(new Set(result));
    },
    [store],
  );

  const getBeneficiary = useCallback(
    (id: string | number) => {
      if (typeof id === 'string')
        return store.collections.beneficiaries?.findOne(
          (beneficiary) => beneficiary.id === id,
        );
      return store.collections.beneficiaries?.findOne(
        (beneficiary) => beneficiary.centralDbProfileId === id,
      );
    },
    [store],
  );

  const getBeneficiaryAdditionalInfo = useCallback(
    (id: string) => {
      return store.collections.beneficiaryPersonalDataDocuments?.findOne(
        (document) => document.beneficiaryDtoId === id,
      );
    },
    [store],
  );

  const getBeneficiaryNotifications = useCallback(
    (id: string) => {
      return store.collections.delegatingRequests?.findMany(
        (request) =>
          request.status === DelegatingRequestStatus.WAITING_FOR_APPROVE &&
          request.relatedEntity?.dtoId === id,
      );
    },
    [store],
  );

  const getBeneficiariesWithValue = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (id?: string) => {
      const scenarios = await getScenarios();
      const beneficiariesFromDb = await getCentralDbBeneficiaries();

      if (!scenarios.data?.scenarios) return undefined;

      const result: Record<
        string,
        BeneficiaryDto & { value: number; isActivated: boolean }
      > = {};

      const beneficiariesAssets: Record<string, Array<string>> = {};

      for (const scenario of scenarios.data.scenarios) {
        if (scenario.status !== ScenarioStatus.Done) continue;

        const beneficiary = store.collections.beneficiaries?.findOne(
          (b) => b.centralDbProfileId === scenario.beneficiaryId,
        );

        if (!beneficiary) continue;

        const asset = store.collections.assets?.findOne(
          (a) => !!scenario.dtoIds?.includes(a.id),
        );

        if (!asset) continue;

        const assets = beneficiariesAssets[beneficiary.id]
          ? beneficiariesAssets[beneficiary.id]
          : [];

        if (assets.includes(asset.id)) continue;

        assets.push(asset.id);

        beneficiariesAssets[beneficiary.id] = assets;

        result[beneficiary.id] = {
          ...beneficiary,
          value: assets
            .map((item) => getAsset(item)?.purchaseValue?.calculatedValue)
            .reduce(
              (acc, currentValue) => acc + parseFloat(currentValue || '0'),
              0,
            ),
          // typeof result[beneficiary.id]?.value === 'number'
          //   ? asset.purchaseValue
          //     ? assets
          //         .map(
          //           (item) => getAsset(item)?.purchaseValue?.calculatedValue,
          //         )
          //         .reduce(
          //           (acc, currentValue) =>
          //             acc + parseFloat(currentValue || '0'),
          //           0,
          //         )
          //     : 0
          //   : 0,
          isActivated:
            beneficiariesFromDb.data?.getBeneficiaries.find(
              (b) => b.id === beneficiary.centralDbProfileId,
            )?.status === AccountStatus.Active,
        };
      }

      const resultIds = Object.keys(result);

      for (const b of getBeneficiariesList('all')) {
        if (resultIds.includes(b.id)) continue;

        result[b.id] = {
          ...b,
          value: 0,
          isActivated:
            beneficiariesFromDb.data?.getBeneficiaries.find(
              (_b) => _b.id === b.centralDbProfileId,
            )?.status === AccountStatus.Active,
        };
      }

      return id
        ? result[id]
          ? [result[id]]
          : undefined
        : Object.values(result);
    },
    [store, getScenarios, getCentralDbBeneficiaries],
  );

  const getAllBeneficiaryPersonalIdentifiers = useCallback(() => {
    return store.collections.beneficiaryPersonalDataDocuments?.findMany(
      () => true,
    );
  }, [store]);

  const isBeneficiaryWithScenario = useCallback(
    (beneficiaryCentralDbId: number) => {
      return !!dataScenarios?.scenarios?.find(
        (b) => b.beneficiaryId === beneficiaryCentralDbId,
      );
    },
    [],
  );

  const deleteBeneficiary = useCallback(
    (id: string) => {
      store.collections.beneficiaries?.remove({ id } as BeneficiaryDto);
    },
    [store],
  );

  return useMemo(
    () => ({
      sizes,
      getBeneficiariesList,
      getBeneficiary,
      getBeneficiaryAdditionalInfo,
      getBeneficiaryNotifications,
      getBeneficiariesWithValue,
      getAllBeneficiaryPersonalIdentifiers,
      isBeneficiaryWithScenario,
      deleteBeneficiary,
      loading: scenariosLoading,
    }),
    [
      sizes,
      getBeneficiariesList,
      getBeneficiary,
      getBeneficiaryAdditionalInfo,
      getBeneficiaryNotifications,
      getBeneficiariesWithValue,
      getAllBeneficiaryPersonalIdentifiers,
      isBeneficiaryWithScenario,
      deleteBeneficiary,
      scenariosLoading,
    ],
  );
};
