import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum PreciousGemstonesType {
  diamond = 'diamond',
  ruby = 'ruby',
  emerald = 'emerald',
  sapphire = 'sapphire',
}

const PreciousGemstonesList = [
  {
    label: 'forms_assets:gemstones.enums.type.diamond',
    key: PreciousGemstonesType.diamond,
  },
  {
    label: 'forms_assets:gemstones.enums.type.ruby',
    key: PreciousGemstonesType.ruby,
  },
  {
    label: 'forms_assets:gemstones.enums.type.emerald',
    key: PreciousGemstonesType.emerald,
  },
  {
    label: 'forms_assets:gemstones.enums.type.sapphire',
    key: PreciousGemstonesType.sapphire,
  },
];

export enum TypeOfOrigin {
  natural = 'natural',
  synthetic = 'synthetic',
}

const TypeOfOriginList = [
  {
    label: 'forms_assets:gemstones.enums.origin.natural',
    key: TypeOfOrigin.natural,
  },
  {
    label: 'forms_assets:gemstones.enums.origin.synthetic',
    key: TypeOfOrigin.synthetic,
  },
];

export enum AppraisalLaboratoryType {
  GIA = 'GIA',
  HRD = 'HRD',
  IGI = 'IGI',
  EGL = 'EGL',
  GAGLT = 'GAGLT',
  ISDI = 'ISDI',
  INDI = 'INDI',
}

const AppraisalLaboratoryList = [
  {
    label: 'forms_assets:gemstones.enums.appraisalLaboratory.GIA',
    key: AppraisalLaboratoryType.GIA,
  },
  {
    label: 'forms_assets:gemstones.enums.appraisalLaboratory.HRD',
    key: AppraisalLaboratoryType.HRD,
  },
  {
    label: 'forms_assets:gemstones.enums.appraisalLaboratory.IGI',
    key: AppraisalLaboratoryType.IGI,
  },
  {
    label: 'forms_assets:gemstones.enums.appraisalLaboratory.EGL',
    key: AppraisalLaboratoryType.EGL,
  },
  {
    label: 'forms_assets:gemstones.enums.appraisalLaboratory.GAGLT',
    key: AppraisalLaboratoryType.GAGLT,
  },
  {
    label: 'forms_assets:gemstones.enums.appraisalLaboratory.ISDI',
    key: AppraisalLaboratoryType.ISDI,
  },
  {
    label: 'forms_assets:gemstones.enums.appraisalLaboratory.INDI',
    key: AppraisalLaboratoryType.INDI,
  },
];

export enum ColorType {
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  other = 'other',
}

const ColorList = [
  {
    label: 'forms_assets:gemstones.enums.color.D',
    key: ColorType.D,
  },
  {
    label: 'forms_assets:gemstones.enums.color.E',
    key: ColorType.E,
  },
  {
    label: 'forms_assets:gemstones.enums.color.F',
    key: ColorType.F,
  },
  {
    label: 'forms_assets:gemstones.enums.color.G',
    key: ColorType.G,
  },
  {
    label: 'forms_assets:gemstones.enums.color.H',
    key: ColorType.H,
  },
  {
    label: 'forms_assets:gemstones.enums.color.I',
    key: ColorType.I,
  },
  {
    label: 'forms_assets:gemstones.enums.color.J',
    key: ColorType.J,
  },
  {
    label: 'forms_assets:gemstones.enums.color.K',
    key: ColorType.K,
  },
  {
    label: 'forms_assets:gemstones.enums.color.L',
    key: ColorType.L,
  },
  {
    label: 'forms_assets:gemstones.enums.color.M',
    key: ColorType.M,
  },
  {
    label: 'forms_assets:gemstones.enums.color.N',
    key: ColorType.N,
  },
  {
    label: 'forms_assets:gemstones.enums.color.O',
    key: ColorType.O,
  },
  {
    label: 'forms_assets:gemstones.enums.color.P',
    key: ColorType.P,
  },
  {
    label: 'forms_assets:gemstones.enums.color.Q',
    key: ColorType.Q,
  },
  {
    label: 'forms_assets:gemstones.enums.color.R',
    key: ColorType.R,
  },
  {
    label: 'forms_assets:gemstones.enums.color.S',
    key: ColorType.S,
  },
  {
    label: 'forms_assets:gemstones.enums.color.T',
    key: ColorType.T,
  },
  {
    label: 'forms_assets:gemstones.enums.color.U',
    key: ColorType.U,
  },
  {
    label: 'forms_assets:gemstones.enums.color.V',
    key: ColorType.V,
  },
  {
    label: 'forms_assets:gemstones.enums.color.W',
    key: ColorType.W,
  },
  {
    label: 'forms_assets:gemstones.enums.color.X',
    key: ColorType.X,
  },
  {
    label: 'forms_assets:gemstones.enums.color.Y',
    key: ColorType.Y,
  },
  {
    label: 'forms_assets:gemstones.enums.color.Z',
    key: ColorType.Z,
  },
  {
    label: 'forms_assets:gemstones.enums.color.other',
    key: ColorType.other,
  },
];

export enum ClarityType {
  flawless = 'flawless',
  internallyFlawless = 'internallyFlawless',
  VVS1 = 'VVS1',
  VVS2 = 'VVS2',
  VS1 = 'VS1',
  VS2 = 'VS2',
  S1 = 'S1',
  S2 = 'S2',
  I1 = 'I1',
  I2 = 'I2',
  I3 = 'I3',
  other = 'other',
}

const ClarityList = [
  {
    label: 'forms_assets:gemstones.enums.clarityType.flawless',
    key: ClarityType.flawless,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.internallyFlawless',
    key: ClarityType.internallyFlawless,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.VVS1',
    key: ClarityType.VVS1,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.VVS2',
    key: ClarityType.VVS2,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.VS1',
    key: ClarityType.VS1,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.VS2',
    key: ClarityType.VS2,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.S1',
    key: ClarityType.S1,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.S2',
    key: ClarityType.S2,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.I1',
    key: ClarityType.I1,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.I2',
    key: ClarityType.I2,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.I3',
    key: ClarityType.I3,
  },
  {
    label: 'forms_assets:gemstones.enums.clarityType.other',
    key: ClarityType.other,
  },
];

export enum CutAndPolishType {
  excellent = 'excellent',
  veryGood = 'veryGood',
  good = 'good',
  fair = 'fair',
  poor = 'poor',
  other = 'other',
}

const CutAndPolishList = [
  {
    label: 'forms_assets:gemstones.enums.cutAndPolish.excellent',
    key: CutAndPolishType.excellent,
  },
  {
    label: 'forms_assets:gemstones.enums.cutAndPolish.veryGood',
    key: CutAndPolishType.veryGood,
  },
  {
    label: 'forms_assets:gemstones.enums.cutAndPolish.good',
    key: CutAndPolishType.good,
  },
  {
    label: 'forms_assets:gemstones.enums.cutAndPolish.fair',
    key: CutAndPolishType.fair,
  },
  {
    label: 'forms_assets:gemstones.enums.cutAndPolish.poor',
    key: CutAndPolishType.poor,
  },
  {
    label: 'forms_assets:gemstones.enums.cutAndPolish.other',
    key: CutAndPolishType.other,
  },
];

export enum ShapeType {
  cushion = 'cushion',
  marquise = 'marquise',
  radiant = 'radiant',
  pear = 'pear',
  round = 'round',
  oval = 'oval',
  emerald = 'emerald',
  princess = 'princess',
  heart = 'heart',
  other = 'other',
}

const ShapeList = [
  {
    label: 'forms_assets:gemstones.enums.shape.cushion',
    key: ShapeType.cushion,
  },
  {
    label: 'forms_assets:gemstones.enums.shape.marquise',
    key: ShapeType.marquise,
  },
  {
    label: 'forms_assets:gemstones.enums.shape.radiant',
    key: ShapeType.radiant,
  },
  {
    label: 'forms_assets:gemstones.enums.shape.pear',
    key: ShapeType.pear,
  },
  {
    label: 'forms_assets:gemstones.enums.shape.round',
    key: ShapeType.round,
  },
  {
    label: 'forms_assets:gemstones.enums.shape.oval',
    key: ShapeType.oval,
  },
  {
    label: 'forms_assets:gemstones.enums.shape.emerald',
    key: ShapeType.emerald,
  },
  {
    label: 'forms_assets:gemstones.enums.shape.princess',
    key: ShapeType.princess,
  },
  {
    label: 'forms_assets:gemstones.enums.shape.heart',
    key: ShapeType.heart,
  },
  {
    label: 'forms_assets:gemstones.enums.shape.other',
    key: ShapeType.other,
  },
];

export enum FluorescenceType {
  none = 'none',
  slight = 'slight',
  medium = 'medium',
  strong = 'strong',
  veryStrong = 'veryStrong',
  other = 'other',
}

const FluorescenceList = [
  {
    label: 'forms_assets:gemstones.enums.fluorescence.none',
    key: FluorescenceType.none,
  },
  {
    label: 'forms_assets:gemstones.enums.fluorescence.slight',
    key: FluorescenceType.slight,
  },
  {
    label: 'forms_assets:gemstones.enums.fluorescence.medium',
    key: FluorescenceType.medium,
  },
  {
    label: 'forms_assets:gemstones.enums.fluorescence.strong',
    key: FluorescenceType.strong,
  },
  {
    label: 'forms_assets:gemstones.enums.fluorescence.veryStrong',
    key: FluorescenceType.veryStrong,
  },
  {
    label: 'forms_assets:gemstones.enums.fluorescence.other',
    key: ShapeType.other,
  },
];

export enum TypeOfHistoryOfOriginType {
  gift = 'gift',
  purchase = 'purchase',
  inheritance = 'inheritance',
  other = 'other',
}

const TypeOfHistoryOfOriginList = [
  {
    label: 'forms_assets:gemstones.enums.typeOfHistoryOfOrigin.gift',
    key: TypeOfHistoryOfOriginType.gift,
  },
  {
    label: 'forms_assets:gemstones.enums.typeOfHistoryOfOrigin.purchase',
    key: TypeOfHistoryOfOriginType.purchase,
  },
  {
    label: 'forms_assets:gemstones.enums.typeOfHistoryOfOrigin.inheritance',
    key: TypeOfHistoryOfOriginType.inheritance,
  },
  {
    label: 'forms_assets:gemstones.enums.typeOfHistoryOfOrigin.other',
    key: TypeOfHistoryOfOriginType.other,
  },
];

type PreciousGemstonesPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  type: PreciousGemstonesType;
  typeOfOrigin?: TypeOfOrigin;
  purchaseValue: number;
  weight?: string;
  countryOfAcquisition: string;
  storageLocation?: string;
};

type PreciousGemstonesPart2 = Omit<BaseDto, 'id'> & {
  personalAppraisal?: number;
  independentAppraisalValue?: number;
  appraisalLaboratory?: AppraisalLaboratoryType;
  color?: ColorType;
  otherColor?: string;
  clarity?: ClarityType;
  otherClarity?: string;
  enhancements?: boolean;
  defects?: string;
  cut?: CutAndPolishType;
  otherCut?: string;
  sizes?: string;
  countryOfOrigin?: string;
  serialNumber?: string;
  polish?: CutAndPolishType;
  otherPolish?: string;
  shape?: ShapeType;
  otherShape?: string;
  fluorescence?: FluorescenceType;
  otherFluorescence?: string;
  historyOfOrigin?: FormLabelItem;
  typeOfHistoryOfOrigin?: TypeOfHistoryOfOriginType;
  otherTypeOfHistoryOfOrigin?: string;
  occasion?: string;
  source?: string;
  acquisitionDate?: Date;
  writeOffAccount?: string;
  purchaseReceipt?: DtoId[];
  internationalCertificate?: DtoId[];
  additionalAppraisal?: DtoId[];
  insurance?: boolean;
  insuranceDocument?: DtoId[];
  photo?: string;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type PreciousGemstonesHelpPart1 = {
  nickName: QuestionType.TEXT;
  type: QuestionType.SELECT_FROM_LIST;
  typeOfOrigin: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  weight: QuestionType.TEXT;
  countryOfAcquisition: QuestionType.SELECT_FROM_LIST;
  storageLocation: QuestionType.TEXT;
};

type PreciousGemstonesHelpPart2 = {
  personalAppraisal?: QuestionType.CURRENCY_CALCULATOR;
  independentAppraisalValue?: QuestionType.CURRENCY_CALCULATOR;
  purchaseValueEquivalent?: QuestionType.NUMBER;
  independentAppraisalValueEquivalent?: QuestionType.NUMBER;
  appraisalLaboratory?: QuestionType.SELECT_FROM_LIST;
  color?: QuestionType.SELECT_FROM_LIST;
  otherColor?: QuestionType.TEXT;
  clarity?: QuestionType.SELECT_FROM_LIST;
  otherClarity?: QuestionType.TEXT;
  enhancements?: QuestionType.CHECKBOX;
  defects?: QuestionType.TEXT;
  cut?: QuestionType.SELECT_FROM_LIST;
  otherCut?: QuestionType.TEXT;
  sizes?: QuestionType.TEXT;
  countryOfOrigin?: QuestionType.TEXT;
  serialNumber?: QuestionType.TEXT;
  polish?: QuestionType.SELECT_FROM_LIST;
  otherPolish?: QuestionType.TEXT;
  shape?: QuestionType.SELECT_FROM_LIST;
  otherShape?: QuestionType.TEXT;
  fluorescence?: QuestionType.SELECT_FROM_LIST;
  otherFluorescence?: QuestionType.TEXT;
  typeOfHistoryOfOrigin?: QuestionType.SELECT_FROM_LIST;
  otherTypeOfHistoryOfOrigin?: QuestionType.TEXT;
  occasion?: QuestionType.TEXT;
  source?: QuestionType.TEXT;
  acquisitionDate?: QuestionType.DATE_TIME;
  purchaseReceipt?: QuestionType.PICK_ARRAY_OF_DTO;
  writeOffAccount?: QuestionType.TEXT;
  internationalCertificate?: QuestionType.PICK_ARRAY_OF_DTO;
  additionalAppraisal?: QuestionType.PICK_ARRAY_OF_DTO;
  insurance?: QuestionType.CHECKBOX;
  insuranceDocument?: QuestionType.PICK_ARRAY_OF_DTO;
  photo?: QuestionType.FILE_UPLOAD;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type PreciousGemstonesFormType = [
  PreciousGemstonesFormPart1Type,
  PreciousGemstonesFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type PreciousGemstonesFormPart1Type = TDtoWrapper<
  PreciousGemstonesPart1,
  PreciousGemstonesHelpPart1
>;
type PreciousGemstonesFormPart2Type = TDtoWrapper<
  PreciousGemstonesPart2,
  PreciousGemstonesHelpPart2
>;

const PreciousGemstonesFormPart1: PreciousGemstonesFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  countryOfAcquisition: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.countryOfAcquisition.label',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.type.label',
    value: PreciousGemstonesType.diamond,
    required: true,
    items: PreciousGemstonesList,
  },
  typeOfOrigin: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.typeOfOrigin.label',
    value: TypeOfOrigin.natural,
    // required: true,
    items: TypeOfOriginList,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:gemstones.inputs.personalAppraisal.label',
    tooltip: 'forms_assets:gemstones.inputs.personalAppraisal.tooltip',
    equivalentValueTooltip:
      // eslint-disable-next-line sonarjs/no-duplicate-string
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  weight: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.weight.label',
    value: '',
    // required: true,
    yupScheme: yup.string().required('Please enter carat weight.'),
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.storageLocation.label',
    tooltip: 'forms_assets:gemstones.inputs.storageLocation.tooltip',
    value: '',
    // required: true,
    yupScheme: yup.string().required('Please enter storage location.'),
  },
};

const PreciousGemstonesFormPart2: PreciousGemstonesFormPart2Type = {
  personalAppraisal: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:gemstones.inputs.purchaseValue.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  independentAppraisalValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:gemstones.inputs.independentAppraisalValue.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 2,
  },
  appraisalLaboratory: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.appraisalLaboratory.label',
    order: 3,
    items: AppraisalLaboratoryList,
  },
  color: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.color.label',
    order: 4,
    items: ColorList,
  },
  otherColor: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.otherColor.label',
    order: 5,
    showIf: {
      color: {
        value: ColorType.other,
      },
    },
  },
  clarity: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.clarity.label',
    order: 6,
    items: ClarityList,
  },
  otherClarity: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.otherClarity.label',
    order: 7,
    showIf: {
      clarity: {
        value: ClarityType.other,
      },
    },
  },
  cut: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.cut.label',
    order: 8,
    items: CutAndPolishList,
  },
  otherCut: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.otherCut.label',
    order: 9,
    showIf: {
      cut: {
        value: CutAndPolishType.other,
      },
    },
  },
  defects: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.defects.label',
    order: 10,
  },
  enhancements: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:gemstones.inputs.enhancements.label',
    tooltip: 'forms_assets:gemstones.inputs.enhancements.tooltip',
    order: 11,
  },
  sizes: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.sizes.label',
    order: 12,
  },
  countryOfOrigin: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.countryOfOrigin.label',
    order: 13,
    items: CountriesList,
  },
  serialNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.serialNumber.label',
    order: 14,
  },
  polish: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.polish.label',
    order: 15,
    items: CutAndPolishList,
  },
  otherPolish: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.otherPolish.label',
    order: 16,
    showIf: {
      polish: {
        value: CutAndPolishType.other,
      },
    },
  },
  shape: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.shape.label',
    order: 17,
    items: ShapeList,
  },
  otherShape: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.otherShape.label',
    order: 18,
    showIf: {
      shape: {
        value: ShapeType.other,
      },
    },
  },
  fluorescence: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.fluorescence.label',
    tooltip: 'forms_assets:gemstones.inputs.fluorescence.tooltip',
    order: 19,
    items: FluorescenceList,
  },
  otherFluorescence: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.otherFluorescence.label',
    order: 20,
    showIf: {
      fluorescence: {
        value: FluorescenceType.other,
      },
    },
  },
  acquisitionDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:gemstones.inputs.acquisitionDate.label',
    order: 21,
  },
  writeOffAccount: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.writeOffAccount.label',
    order: 22,
  },
  purchaseReceipt: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:gemstones.inputs.purchaseReceipt.label',
    order: 23,
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  internationalCertificate: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:gemstones.inputs.internationalCertificate.label',
    order: 24,
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  additionalAppraisal: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:gemstones.inputs.additionalAppraisal.label',
    order: 25,
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  insurance: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:gemstones.inputs.insurance.label',
    order: 26,
  },
  insuranceDocument: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:gemstones.inputs.insuranceDocument.label',
    order: 27,
    items: 'privateDocuments',
    showIf: {
      insurance: {
        value: true,
      },
    },
    dtoType: PrivateDocumentCategory.OTHER,
  },
  photo: {
    type: QuestionType.FILE_UPLOAD,
    label: 'forms_assets:gemstones.inputs.photo.label',
    tooltip: 'forms_assets:gemstones.inputs.photo.tooltip',
    order: 28,
  },
  historyOfOrigin: {
    type: FormLabelItem.label,
    label: 'forms_assets:gemstones.inputs.historyOfOrigin.label',
    order: 29,
  },
  typeOfHistoryOfOrigin: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.typeOfHistoryOfOrigin.label',
    order: 30,
    items: TypeOfHistoryOfOriginList,
  },
  otherTypeOfHistoryOfOrigin: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.otherTypeOfHistoryOfOrigin.label',
    order: 31,
    showIf: {
      typeOfHistoryOfOrigin: {
        value: TypeOfHistoryOfOriginType.other,
      },
    },
  },
  occasion: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.occasion.label',
    tooltip: 'forms_assets:gemstones.inputs.occasion.tooltip',
    order: 32,
  },
  source: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.source.label',
    tooltip: 'forms_assets:gemstones.inputs.source.tooltip',
    order: 33,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const PreciousGemstonesForm: PreciousGemstonesFormType = [
  PreciousGemstonesFormPart1,
  PreciousGemstonesFormPart2,
  assetsOwnershipBuilder([
    'contactInfoLabel',
    'contactInfoLabelDescription',
    'address',
    'phoneNumber',
    'email',
  ]).form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgent',
    'publicFundTaxAgent',
    'taxAgentOther',
    'taxAgentWithBrokerOther',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { PreciousGemstonesForm };
