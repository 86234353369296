import { FC } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Input, message, Popover } from 'antd';
import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';
import { copy } from '@/shared/utils/misc';

type CopyComponentProps = {
  code: string;
  copiedText?: string;
  setIsGenerated: () => void;
};

export const CopyComponent: FC<CopyComponentProps> = ({
  code,
  copiedText,
  setIsGenerated,
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const clickCopy = async () => {
    try {
      await copy(code);
      if (copiedText) {
        messageApi.open({ type: 'success', content: copiedText });
      }
      setIsGenerated();
    } catch (e) {
      //
    }
  };

  return (
    <>
      {contextHolder}
      <StyledInput
        data-testId="generated"
        value={code}
        suffix={
          <Popover content={<span>Copied</span>} trigger="click">
            <CopyOutlined onClick={clickCopy} />
          </Popover>
        }
      />
    </>
  );
};

const StyledInput = styled(Input)`
  &&& {
    input {
      color: ${COLORS.colorLink};
    }
  }
`;
