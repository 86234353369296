import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum BondsEnum {
  corporate = 'corporate',
  municipal = 'municipal',
  sovereign = 'sovereign',
}

const BondsList = [
  {
    label: 'forms_assets:bonds.enums.category.corporate',
    key: BondsEnum.corporate,
  },
  {
    label: 'forms_assets:bonds.enums.category.municipal',
    key: BondsEnum.municipal,
  },
  {
    label: 'forms_assets:bonds.enums.category.sovereign',
    key: BondsEnum.sovereign,
  },
];

enum BondType {
  classic = 'classic',
  secure = 'secure',
}

const BondTypeList = [
  { label: 'forms_assets:bonds.enums.type.classic', key: BondType.classic },
  { label: 'forms_assets:bonds.enums.type.secure', key: BondType.secure },
];

enum TradeType {
  etc = 'etc',
  otc = 'otc',
}

const TradeTypesList = [
  { label: 'forms_assets:bonds.enums.tradeType.etc', key: TradeType.etc },
  { label: 'forms_assets:bonds.enums.tradeType.otc', key: TradeType.otc },
];

export enum RatingType {
  AAA = 'AAA',
  AA = 'AA',
  A = 'A',
  BBB = 'BBB',
  bb = 'bb',
  B = 'B',
  CCCp = 'ccc+',
  CCC = 'ccc',
  C_CCCm = 'c_to_ccc-',
  D = 'd',
}

const RatingTypesList = [
  { label: 'forms_assets:bonds.enums.rating.AAA', key: RatingType.AAA },
  { label: 'forms_assets:bonds.enums.rating.AA', key: RatingType.AA },
  {
    label: 'forms_assets:bonds.enums.rating.A',
    key: RatingType.A,
  },
  {
    label: 'forms_assets:bonds.enums.rating.BBB',
    key: RatingType.BBB,
  },
  { label: 'forms_assets:bonds.enums.rating.bb', key: RatingType.bb },
  {
    label: 'forms_assets:bonds.enums.rating.B',
    key: RatingType.B,
  },
  { label: 'forms_assets:bonds.enums.rating.CCCp', key: RatingType.CCCp },
  { label: 'forms_assets:bonds.enums.rating.CCC', key: RatingType.CCC },
  {
    label: 'forms_assets:bonds.enums.rating.C_CCCm',
    key: RatingType.C_CCCm,
  },
  { label: 'forms_assets:bonds.enums.rating.D', key: RatingType.D },
];

type BondsPart1Type = Omit<BaseDto, 'id'> & {
  nickname: string;
  country: string;
  type: BondsEnum;
  purchaseValue: number;
  keyAssets?: string;
  keyAssetsQuantity?: number;
  holder?: DtoId[];
};

type BondsPart2Type = Omit<BaseDto, 'id'> & {
  currentMarketValue?: number;
  issuedBy?: string;
  isin?: string;
  acquisitionDate?: Date;
  maturityDate?: Date;
  brokerageAccountNumber?: string;
  depositaryName?: string;
  depositaryAccountNumber?: string;
  tradeType?: TradeType;
  seller?: string;
  tradingPlatform?: string;
  insurance?: boolean;
  bondsType?: BondType;
  bondsRate?: RatingType;
  strategy?: string;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type BondsHelpPart1Type = {
  nickname: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  keyAssets: QuestionType.TEXT;
  keyAssetsQuantity: QuestionType.NUMBER;
  holder: QuestionType.PICK_ARRAY_OF_DTO;
};

type BondsHelpPart2Type = {
  currentMarketValue?: QuestionType.CURRENCY_CALCULATOR;
  issuedBy?: QuestionType.TEXT;
  isin?: QuestionType.TEXT;
  acquisitionDate?: QuestionType.DATE_TIME;
  maturityDate?: QuestionType.DATE_TIME;
  brokerageAccountNumber?: QuestionType.TEXT;
  depositaryName?: QuestionType.TEXT;
  depositaryAccountNumber?: QuestionType.TEXT;
  seller?: QuestionType.TEXT;
  tradeType?: QuestionType.SELECT_FROM_LIST;
  tradingPlatform?: QuestionType.TEXT;
  insurance?: QuestionType.CHECKBOX;
  bondsType?: QuestionType.SELECT_FROM_LIST;
  bondsRate?: QuestionType.SELECT_FROM_LIST;
  strategy?: QuestionType.TEXT;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type BondsFormPart1Type = TDtoWrapper<BondsPart1Type, BondsHelpPart1Type>;

type BondsFormPart2Type = TDtoWrapper<BondsPart2Type, BondsHelpPart2Type>;

export type BondsFormType = [
  BondsFormPart1Type,
  BondsFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

const BondsFormPart1: BondsFormPart1Type = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:bonds.inputs.type.label',
    tooltip: 'forms_assets:bonds.inputs.type.tooltip',
    value: BondsEnum.corporate,
    required: true,
    items: BondsList,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:bonds.inputs.marketValue.label',
    tooltip: 'forms_assets:bonds.inputs.marketValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  keyAssets: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bonds.inputs.keyAssets.label',
    // required: true,
    value: '',
  },
  keyAssetsQuantity: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:bonds.inputs.keyAssetsQuantity.label',
    // required: true,
    value: 0,
  },
  holder: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:bonds.inputs.holder.label',
    value: [],
    items: 'contacts',
    tooltip: 'forms_assets:bonds.inputs.holder.tooltip',
    // required: true,
    filter: (contact) =>
      (contact as unknown as ContactDto).type === ContactType.BROKER,
    dtoType: ContactType.BROKER,
  },
};
const BondsFormPart2: BondsFormPart2Type = {
  currentMarketValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:bonds.inputs.purchaseValue.label',
    tooltip: 'forms_assets:bonds.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  issuedBy: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bonds.inputs.issuedBy.label',
    order: 2,
  },
  isin: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bonds.inputs.isin.label',
    tooltip: 'forms_assets:bonds.inputs.isin.tooltip',
    order: 3,
  },
  acquisitionDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:bonds.inputs.acquisitionDate.label',
    order: 4,
  },
  maturityDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:bonds.inputs.maturityDate.label',
    order: 5,
  },
  brokerageAccountNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bonds.inputs.brokerageAccountNumber.label',
    order: 6,
  },
  depositaryName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bonds.inputs.depositaryName.label',
    order: 7,
  },
  depositaryAccountNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bonds.inputs.depositaryAccountNumber.label',
    order: 8,
  },
  tradeType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:bonds.inputs.tradeType.label',
    order: 9,

    items: TradeTypesList,
  },
  tradingPlatform: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bonds.inputs.tradingPlatform.label',
    order: 10,

    showIf: {
      tradeType: {
        value: TradeType.etc,
      },
    },
  },
  seller: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bonds.inputs.seller.label',
    order: 11,
    showIf: {
      tradeType: {
        value: TradeType.otc,
      },
    },
  },
  insurance: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:bonds.inputs.insurance.label',
    order: 12,
  },
  bondsType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:bonds.inputs.bondsType.label',
    order: 13,

    items: BondTypeList,
  },
  bondsRate: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:bonds.inputs.bondsRate.label',
    order: 14,

    items: RatingTypesList,
  },
  strategy: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bonds.inputs.strategy.label',
    order: 15,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const BondsForm = [
  BondsFormPart1,
  BondsFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgent',
    'taxAgentOther',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { BondsForm };
