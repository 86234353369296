import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export type DebitAccount = DebitAccountFormPart1Type &
  DebitAccountFormPart2Type &
  AssetsOwnershipPartType &
  AssetsStatisticsPartType;

export enum DebitType {
  currentAccount = 'currentAccount',
  deposit = 'deposit',
}

const DebitList = [
  {
    label: 'forms_assets:debitAccount.enums.type.currentAccount',
    key: DebitType.currentAccount,
  },
  {
    label: 'forms_assets:debitAccount.enums.type.deposit',
    key: DebitType.deposit,
  },
];

// export const enum AccountTermType {
//   fixed = 'fixed',
//   nonFixed = 'nonFixed',
// }

// const AccountTermList = [
//   {
//     label: 'forms_assets:debitAccount.enums.term.fixed',
//     key: AccountTermType.fixed,
//   },
//   {
//     label: 'forms_assets:debitAccount.enums.term.nonFixed',
//     key: AccountTermType.nonFixed,
//   },
// ];

type DebitAccountPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type: DebitType;
  purchaseValue: number;
  bank: DtoId[];
};
type DebitAccountPart2 = Omit<BaseDto, 'id'> & {
  cif?: string;
  iban?: string;
  dateOfOpening?: Date;
  fixedTermAccount?: boolean;
  activeUntil?: Date;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type DebitAccountHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  bank: QuestionType.PICK_ARRAY_OF_DTO; //fix
};
type DebitAccountHelpPart2 = {
  cif?: QuestionType.TEXT;
  iban?: QuestionType.TEXT;
  dateOfOpening?: QuestionType.DATE_TIME;
  fixedTermAccount?: QuestionType.CHECKBOX;
  activeUntil?: QuestionType.DATE_TIME;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type DebitAccountFormType = [
  DebitAccountFormPart1Type,
  DebitAccountFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type DebitAccountFormPart1Type = TDtoWrapper<
  DebitAccountPart1,
  DebitAccountHelpPart1
>;
type DebitAccountFormPart2Type = TDtoWrapper<
  DebitAccountPart2,
  DebitAccountHelpPart2
>;

const DebitAccountFormPart1: DebitAccountFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:debitAccount.inputs.type.label',
    value: DebitType.currentAccount,
    required: true,
    items: DebitList,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:debitAccount.inputs.currentValue.label',
    tooltip: 'forms_assets:debitAccount.inputs.currentValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  bank: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:debitAccount.inputs.bank.label',
    tooltip: 'forms_assets:debitAccount.inputs.bank.tooltip',
    items: 'contacts',
    // required: true,
    filter: (contact) =>
      (contact as unknown as ContactDto).type === ContactType.BANK,
    value: [],
    dtoType: ContactType.BANK,
  },
};

const DebitAccountFormPart2: DebitAccountFormPart2Type = {
  cif: {
    type: QuestionType.TEXT,
    label: 'forms_assets:debitAccount.inputs.cif.label',
    order: 1,
  },
  iban: {
    type: QuestionType.TEXT,
    label: 'forms_assets:debitAccount.inputs.iban.label',
    order: 2,
  },
  dateOfOpening: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:debitAccount.inputs.dateOfOpening.label',
    order: 3,
  },
  fixedTermAccount: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:debitAccount.inputs.fixedTermAccount.label',
    order: 4,
  },
  activeUntil: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:debitAccount.inputs.activeUntil.label',
    showIf: {
      fixedTermAccount: {
        value: true,
      },
    },
    order: 5,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const DebitAccountForm: DebitAccountFormType = [
  DebitAccountFormPart1,
  DebitAccountFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgentWithBrokerOther',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { DebitAccountForm };
