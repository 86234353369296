import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { ContactType } from '../../sj-orm/models/contact.dto';

export enum BaseAssetType {
  aluminium = 'aluminium',
  chemicalProducts = 'Chemical products',
  diamonds = 'Diamonds',
  gold = 'Gold',
  grain = 'Grain',
  realEstate = 'realEstate',
  industrialMetals = 'Industrial metals',
  iridium = 'Iridium',
  lumber = 'Lumber',
  mineralProducts = 'Mineral products',
  nickel = 'nickel',
  nonFerrousMetals = 'Non-ferrous metals',
  palladium = 'Palladium',
  petroleum = 'Petroleum',
  petroleumProducts = 'Petroleum products',
  platinum = 'Platinum',
  rareEarthMetals = 'Rare earth metals',
  rhodium = 'Rhodium',
  ruthenium = 'Ruthenium',
  rawMaterial = 'Raw material',
  lead = 'Lead',
  zinc = 'Zinc',
  other = 'other',
}

const BaseAssetTypesList = [
  { label: 'forms_assets:commodity.enums.type.gold', key: BaseAssetType.gold },
  {
    label: 'forms_assets:commodity.enums.type.diamonds',
    key: BaseAssetType.diamonds,
  },
  {
    label: 'forms_assets:commodity.enums.type.iridium',
    key: BaseAssetType.iridium,
  },
  {
    label: 'forms_assets:commodity.enums.type.rhodium',
    key: BaseAssetType.rhodium,
  },
  { label: 'forms_assets:commodity.enums.type.zinc', key: BaseAssetType.zinc },
  { label: 'forms_assets:commodity.enums.type.lead', key: BaseAssetType.lead },
  {
    label: 'forms_assets:commodity.enums.type.aluminium',
    key: BaseAssetType.aluminium,
  },
  {
    label: 'forms_assets:commodity.enums.type.nickel',
    key: BaseAssetType.nickel,
  },
  {
    label: 'forms_assets:commodity.enums.type.nonFerrousMetals',
    key: BaseAssetType.nonFerrousMetals,
  },
  {
    label: 'forms_assets:commodity.enums.type.platinum',
    key: BaseAssetType.platinum,
  },
  {
    label: 'forms_assets:commodity.enums.type.palladium',
    key: BaseAssetType.palladium,
  },
  {
    label: 'forms_assets:commodity.enums.type.rawMaterial',
    key: BaseAssetType.rawMaterial,
  },
  {
    label: 'forms_assets:commodity.enums.type.lumber',
    key: BaseAssetType.lumber,
  },
  {
    label: 'forms_assets:commodity.enums.type.realEstate',
    key: BaseAssetType.realEstate,
  },
  {
    label: 'forms_assets:commodity.enums.type.industrialMetals',
    key: BaseAssetType.industrialMetals,
  },
  {
    label: 'forms_assets:commodity.enums.type.chemicalProducts',
    key: BaseAssetType.chemicalProducts,
  },
  {
    label: 'forms_assets:commodity.enums.type.mineralProducts',
    key: BaseAssetType.mineralProducts,
  },
  {
    label: 'forms_assets:commodity.enums.type.rareEarthMetals',
    key: BaseAssetType.rareEarthMetals,
  },
  {
    label: 'forms_assets:commodity.enums.type.grain',
    key: BaseAssetType.grain,
  },
  {
    label: 'forms_assets:commodity.enums.type.ruthenium',
    key: BaseAssetType.ruthenium,
  },
  {
    label: 'forms_assets:commodity.enums.type.petroleum',
    key: BaseAssetType.petroleum,
  },
  {
    label: 'forms_assets:commodity.enums.type.petroleumProducts',
    key: BaseAssetType.petroleumProducts,
  },
  {
    label: 'forms_assets:commodity.enums.type.other',
    key: BaseAssetType.other,
  },
];

export enum IssuerAndExchangePlatformType {
  informationSystemOperator = 'informationSystemOperator',
  bank = 'bank',
  legalEntity = 'legalEntity',
  other = 'other',
}

const IssuerAndExchangePlatformList = [
  {
    label: 'forms_assets:commodity.enums.platform.informationSystemOperator',
    key: IssuerAndExchangePlatformType.informationSystemOperator,
  },
  {
    label: 'forms_assets:commodity.enums.platform.bank',
    key: IssuerAndExchangePlatformType.bank,
  },
  {
    label: 'forms_assets:commodity.enums.platform.legalEntity',
    key: IssuerAndExchangePlatformType.legalEntity,
  },
  {
    label: 'forms_assets:commodity.enums.platform.other',
    key: IssuerAndExchangePlatformType.other,
  },
];

type CommodityAssetTokenPart1Type = Omit<BaseDto, 'id'> & {
  nickname: string;
  country: string;
  baseAssetType?: BaseAssetType;
  otherBaseAssetType: string;
  purchaseValue: number;
  quantity?: number;
  basicAssetSpecificity: string;
  issuer: string;
  otherIssuer?: string;
  // informationSystemOperator: string;
  // legalEntity: string;
  // bank: DtoId[];
};

type CommodityAssetTokenPart2Type = Omit<BaseDto, 'id'> & {
  // otherPayments?: string;
  purchaseValue2?: number;
  investmentStartDate?: Date;
  investmentEndDate?: Date;
  contractSpecialConditions?: string;
  isTransferableToThirdParty?: boolean;
  issuerLabelInfo?: FormItemLabelType;
  issuerLegalName?: string;
  issuerLocation?: string;
  headCompanyLegalName?: string;
  basicAssetPlaceOfStorage?: string;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
  addExchangePlatform?: boolean;
  exchangePlatform?: IssuerAndExchangePlatformType;
  exchangePlatformInformationSystemOperator?: string;
  exchangePlatformBank?: DtoId[];
  exchangePlatformLegalEntity?: string;
  otherExchangePlatform?: string;
};

type CommodityAssetTokenHelpPart1Type = {
  nickname: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  baseAssetType: QuestionType.SELECT_FROM_LIST;
  otherBaseAssetType: QuestionType.TEXT;
  basicAssetSpecificity: QuestionType.TEXT;
  quantity: QuestionType.NUMBER;
  issuer: QuestionType.SELECT_FROM_LIST;
  otherIssuer: QuestionType.TEXT;
  // informationSystemOperator: QuestionType.TEXT;
  // legalEntity: QuestionType.TEXT;
  // bank: QuestionType.PICK_ARRAY_OF_DTO;
};

type CommodityAssetTokenHelpPart2Type = {
  // otherPayments?: QuestionType.TEXT;
  purchaseValue2?: QuestionType.CURRENCY_CALCULATOR;
  investmentStartDate?: QuestionType.DATE_TIME;
  investmentEndDate?: QuestionType.DATE_TIME;
  contractSpecialConditions?: QuestionType.TEXT;
  isTransferableToThirdParty?: QuestionType.CHECKBOX;
  issuerLegalName?: QuestionType.TEXT;
  issuerLocation?: QuestionType.TEXT;
  headCompanyLegalName?: QuestionType.TEXT;
  basicAssetPlaceOfStorage?: QuestionType.TEXT;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
  addExchangePlatform?: QuestionType.CHECKBOX;
  exchangePlatform?: QuestionType.SELECT_FROM_LIST;
  exchangePlatformInformationSystemOperator?: QuestionType.TEXT;
  exchangePlatformBank?: QuestionType.PICK_ARRAY_OF_DTO;
  exchangePlatformLegalEntity?: QuestionType.TEXT;
  otherExchangePlatform?: QuestionType.TEXT;
};

type CommodityAssetTokenFormPart1Type = TDtoWrapper<
  CommodityAssetTokenPart1Type,
  CommodityAssetTokenHelpPart1Type
>;

type CommodityAssetTokenFormPart2Type = TDtoWrapper<
  CommodityAssetTokenPart2Type,
  CommodityAssetTokenHelpPart2Type
>;

export type CommodityAssetTokenFormType = [
  CommodityAssetTokenFormPart1Type,
  CommodityAssetTokenFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

const CommodityAssetTokenFormPart1: CommodityAssetTokenFormPart1Type = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:commodity.inputs.currentMarketValue.label',
    tooltip: 'forms_assets:commodity.inputs.currentMarketValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  baseAssetType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:commodity.inputs.baseAssetType.label',
    tooltip: 'forms_assets:commodity.inputs.baseAssetType.tooltip',
    value: BaseAssetType.diamonds,
    // required: true,
    items: BaseAssetTypesList,
  },
  otherBaseAssetType: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.otherBaseAssetType.label',
    required: true,
    value: '',
    showIf: {
      baseAssetType: {
        value: BaseAssetType.other,
      },
    },
  },
  basicAssetSpecificity: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.basicAssetSpecificity.label',
    value: '',
  },
  quantity: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:commodity.inputs.quantity.label',
    // required: true,
    value: 0,
  },
  issuer: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:commodity.inputs.issuer.label',
    tooltip: 'forms_assets:commodity.inputs.issuer.tooltip',
    value: '',
    items: IssuerAndExchangePlatformList,
    // required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:commodity.inputs.issuer.errors.required'),
  },
  otherIssuer: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.otherIssuer.label',
    required: true,
    value: '',
    showIf: {
      issuer: {
        value: IssuerAndExchangePlatformType.other,
      },
    },
  },
};

const CommodityAssetTokenFormPart2: CommodityAssetTokenFormPart2Type = {
  // otherPayments: {
  //   type: QuestionType.TEXT,
  //   label: 'forms_assets:commodity.inputs.otherPayments.label',
  //   order: 3,
  // },
  purchaseValue2: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:commodity.inputs.purchaseValue.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  investmentStartDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:commodity.inputs.investmentStartDate.label',
    order: 2,
  },
  investmentEndDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:commodity.inputs.investmentEndDate.label',
    order: 3,
  },
  contractSpecialConditions: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.contractSpecialConditions.label',
    order: 4,
  },
  isTransferableToThirdParty: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:commodity.inputs.isTransferableToThirdParty.label',
    order: 5,
  },
  addExchangePlatform: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:commodity.inputs.addExchangePlatform.label',
    tooltip: 'forms_assets:commodity.inputs.addExchangePlatform.tooltip',
    order: 6,
  },
  exchangePlatform: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:commodity.inputs.exchangePlatform.label',
    items: IssuerAndExchangePlatformList,
    showIf: {
      addExchangePlatform: {
        value: true,
      },
    },
    order: 7,
  },
  exchangePlatformInformationSystemOperator: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.informationSystemOperator.label',
    order: 8,
    showIf: {
      exchangePlatform: {
        value: IssuerAndExchangePlatformType.informationSystemOperator,
      },
    },
  },
  exchangePlatformBank: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:commodity.inputs.bank.label',
    order: 9,
    items: 'contacts',
    showIf: {
      exchangePlatform: {
        value: IssuerAndExchangePlatformType.bank,
      },
    },
    dtoType: ContactType.BANK,
  },
  exchangePlatformLegalEntity: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.legalEntity.label',
    order: 10,
    showIf: {
      exchangePlatform: {
        value: IssuerAndExchangePlatformType.legalEntity,
      },
    },
  },
  otherExchangePlatform: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.otherExchangePlatform.label',
    order: 11,
    showIf: {
      exchangePlatform: {
        value: IssuerAndExchangePlatformType.other,
      },
    },
  },
  issuerLabelInfo: {
    type: FormLabelItem.label,
    label: 'forms_assets:commodity.inputs.issuerLabelInfo.label',
    order: 12,
  },
  issuerLegalName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.issuerLegalName.label',
    tooltip: 'forms_assets:commodity.inputs.issuerLegalName.tooltip',
    order: 13,
  },
  issuerLocation: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.issuerLocation.label',
    order: 14,
  },
  headCompanyLegalName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.headCompanyLegalName.label',
    order: 15,
  },
  basicAssetPlaceOfStorage: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commodity.inputs.basicAssetPlaceOfStorage.label',
    order: 16,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const CommodityAssetTokenForm: CommodityAssetTokenFormType = [
  CommodityAssetTokenFormPart1,
  CommodityAssetTokenFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgent',
    'publicFundTaxAgent',
    'taxAgentOther',
    'taxAgentWithBrokerOther',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { CommodityAssetTokenForm };
