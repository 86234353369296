import { useMemo, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown as AntDropdown } from 'antd';
import styled from 'styled-components';

type TDropdownParams = {
  placeHolder: string;
  items: MenuProps['items'];
  onChange?: (event: TMenuInfo) => void;
  height?: number;
  defaultValue?: string;
  dataCy?: string;
};

export type TMenuInfo = {
  key: string;
  keyPath: string[];
};

export const Dropdown = ({
  height,
  placeHolder,
  items,
  onChange,
  defaultValue,
  dataCy,
}: TDropdownParams) => {
  const _defaultValue = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return items?.find((item) => item.key === defaultValue)?.label;
  }, [items, defaultValue]);

  const [chosen, setChosen] = useState<string>(_defaultValue || placeHolder);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    onChange?.(e);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setChosen(items?.find((item) => item.key === e.key)?.label || '');
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <StyledAntDropdown menu={menuProps} trigger={['click']} height={height}>
      <StyledButton data-testid={dataCy}>
        <span>{chosen}</span>
        <DownOutlined />
      </StyledButton>
    </StyledAntDropdown>
  );
};

const StyledAntDropdown = styled(AntDropdown)<{ height?: number }>`
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  &&& {
    height: ${({ height }) => (height ? `${height}px` : '40px')};
  }
`;

const StyledButton = styled(Button)`
  justify-content: space-between !important;

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
