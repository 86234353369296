import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum ExchangeOptionsType {
  call = 'call',
  put = 'put',
}

const ExchangeOptionsList = [
  {
    label: 'forms_assets:exchangeOptions.enums.optionType.call',
    key: ExchangeOptionsType.call,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.optionType.put',
    key: ExchangeOptionsType.put,
  },
];

export enum OptionStyleType {
  european = 'european ',
  american = 'american ',
  asian = 'asian',
  quasiAmerican = 'quasiAmerican',
  other = 'other',
}

const OptionStyleList = [
  {
    label: 'forms_assets:exchangeOptions.enums.styleType.european',
    key: OptionStyleType.european,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.styleType.american',
    key: OptionStyleType.american,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.styleType.asian',
    key: OptionStyleType.asian,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.styleType.quasiAmerican',
    key: OptionStyleType.quasiAmerican,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.styleType.other',
    key: OptionStyleType.other,
  },
];

export enum BasicAssetType {
  stocks = 'stocks',
  commodity = 'commodity ',
  equity = 'equity',
  currency = 'currency',
  index = 'index',
  interestRate = 'interestRate',
  futuresContract = 'futuresContract',
  other = 'other',
}

const BasicAssetList = [
  {
    label: 'forms_assets:exchangeOptions.enums.basicAssetType.stocks',
    key: BasicAssetType.stocks,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.basicAssetType.commodity',
    key: BasicAssetType.commodity,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.basicAssetType.equity',
    key: BasicAssetType.equity,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.basicAssetType.currency',
    key: BasicAssetType.currency,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.basicAssetType.index',
    key: BasicAssetType.index,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.basicAssetType.interestRate',
    key: BasicAssetType.interestRate,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.basicAssetType.futuresContract',
    key: BasicAssetType.futuresContract,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.basicAssetType.other',
    key: BasicAssetType.other,
  },
];

export enum SellerType {
  legalEntity = 'legalEntity',
  person = 'person',
}

export enum StrategyBasicPositionType {
  buyingCall = 'buyingCall',
  sellingCall = 'sellingCall',
  buyingPut = 'buyingPut',
  sellingPut = 'sellingPut',
}

const StrategyBasicPositionList = [
  {
    label: 'forms_assets:exchangeOptions.enums.strategy.buyingCall',
    key: StrategyBasicPositionType.buyingCall,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.strategy.sellingCall',
    key: StrategyBasicPositionType.sellingCall,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.strategy.buyingPut',
    key: StrategyBasicPositionType.buyingPut,
  },
  {
    label: 'forms_assets:exchangeOptions.enums.strategy.sellingPut',
    key: StrategyBasicPositionType.sellingPut,
  },
];

type ExchangeOptionsPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type: ExchangeOptionsType;
  purchaseValue: number;
  basicAssetName?: string;
  basicAssetType?: BasicAssetType;
  otherBasicAssetType: string;
  basicAssetAmount?: number;
  broker?: DtoId[];
};

type ExchangeOptionsPart2 = Omit<BaseDto, 'id'> & {
  buyingPrice?: number;
  strikePrice?: number;
  exchangeName?: string;
  optionStyle?: OptionStyleType;
  otherOptionStyle?: string;
  optionPeriod?: number;
  sellerName?: string;
  sellerDetails?: string;
  broker?: DtoId[];
  brokerAccountNumber?: string;
  insurance?: boolean;
  dealReserves?: boolean;
  dealReservesData?: string;
  strategyBasicPosition?: StrategyBasicPositionType;
  strategyDescription?: string;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type ExchangeOptionsHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.TEXT;
  type: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  basicAssetName: QuestionType.TEXT;
  basicAssetType: QuestionType.SELECT_FROM_LIST;
  otherBasicAssetType: QuestionType.TEXT;
  basicAssetAmount: QuestionType.NUMBER;
  broker: QuestionType.PICK_ARRAY_OF_DTO;
};

type ExchangeOptionsHelpPart2 = {
  buyingPrice?: QuestionType.CURRENCY_CALCULATOR;
  strikePrice?: QuestionType.CURRENCY_CALCULATOR;
  exchangeName: QuestionType.TEXT;
  optionStyle?: QuestionType.SELECT_FROM_LIST;
  otherOptionStyle?: QuestionType.TEXT;
  basicAssetType?: QuestionType.SELECT_FROM_LIST;
  otherBasicAssetType?: QuestionType.TEXT;
  basicAssetAmount?: QuestionType.NUMBER;
  optionPeriod?: QuestionType.DATE_TIME;
  sellerName?: QuestionType.TEXT;
  sellerDetails?: QuestionType.TEXT_MULTILINE;
  brokerAccountNumber?: QuestionType.TEXT;
  insurance?: QuestionType.CHECKBOX;
  dealReserves?: QuestionType.CHECKBOX;
  dealReservesData?: QuestionType.TEXT;
  strategyBasicPosition?: QuestionType.SELECT_FROM_LIST;
  strategyDescription?: QuestionType.TEXT_MULTILINE;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type ExchangeOptionsFormType = [
  ExchangeOptionsFormPart1Type,
  ExchangeOptionsFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type ExchangeOptionsFormPart1Type = TDtoWrapper<
  ExchangeOptionsPart1,
  ExchangeOptionsHelpPart1
>;

type ExchangeOptionsFormPart2Type = TDtoWrapper<
  ExchangeOptionsPart2,
  ExchangeOptionsHelpPart2
>;

const ExchangeOptionsFormPart1: ExchangeOptionsFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:exchangeOptions.inputs.type.label',
    tooltip: 'forms_assets:exchangeOptions.inputs.type.tooltip',
    required: true,
    value: ExchangeOptionsType.put,
    items: ExchangeOptionsList,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:exchangeOptions.inputs.purchaseValue.label',
    tooltip: 'forms_assets:exchangeOptions.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      // eslint-disable-next-line sonarjs/no-duplicate-string
      'forms_assets:common.inputs.equivalentValue.tooltip',
    required: true,
    value: 0,
  },
  basicAssetType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:exchangeOptions.inputs.basicAssetType.label',
    tooltip: 'forms_assets:exchangeOptions.inputs.basicAssetType.tooltip',
    items: BasicAssetList,
    // required: true,
    value: BasicAssetType.commodity,
  },
  otherBasicAssetType: {
    type: QuestionType.TEXT,
    label: 'forms_assets:exchangeOptions.inputs.otherBasicAssetType.label',
    required: true,
    value: '',
    showIf: {
      basicAssetType: {
        value: BasicAssetType.other,
      },
    },
  },
  basicAssetName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:exchangeOptions.inputs.basicAssetName.label',
    // required: true,
    value: '',
  },
  basicAssetAmount: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:exchangeOptions.inputs.basicAssetAmount.label',
    // required: true,
    value: 0,
  },
  broker: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:exchangeOptions.inputs.broker.label',
    items: 'contacts',
    // required: true,
    value: [],
    filter: (contact) =>
      (contact as unknown as ContactDto).type === ContactType.BROKER,
    dtoType: ContactType.BROKER,
  },
};

const ExchangeOptionsFormPart2: ExchangeOptionsFormPart2Type = {
  buyingPrice: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:exchangeOptions.inputs.buyingPrice.label',
    tooltip: 'forms_assets:exchangeOptions.inputs.buyingPrice.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  strikePrice: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:exchangeOptions.inputs.strikePrice.label',
    tooltip: 'forms_assets:exchangeOptions.inputs.strikePrice.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 2,
  },
  exchangeName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:exchangeOptions.inputs.exchangeName.label',
    value: '',
    order: 3,
  },
  optionStyle: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:exchangeOptions.inputs.optionStyle.label',
    order: 4,
    items: OptionStyleList,
  },
  otherOptionStyle: {
    type: QuestionType.TEXT,
    label: 'forms_assets:exchangeOptions.inputs.otherOptionStyle.label',
    order: 5,
    showIf: {
      optionStyle: {
        value: OptionStyleType.other,
      },
    },
  },
  optionPeriod: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:exchangeOptions.inputs.optionPeriod.label',
    tooltip: 'forms_assets:exchangeOptions.inputs.optionPeriod.tooltip',
    order: 6,
  },
  sellerName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:exchangeOptions.inputs.sellerName.label',
    order: 7,
  },
  sellerDetails: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:exchangeOptions.inputs.sellerDetails.label',
    order: 8,
  },
  brokerAccountNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:exchangeOptions.inputs.brokerAccountNumber.label',
    order: 8,
  },
  insurance: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:exchangeOptions.inputs.insurance.label',
    order: 10,
  },
  dealReserves: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:exchangeOptions.inputs.dealReserves.label',
    tooltip: 'forms_assets:exchangeOptions.inputs.dealReserves.tooltip',
    order: 11,
  },
  dealReservesData: {
    type: QuestionType.TEXT,
    label: 'forms_assets:exchangeOptions.inputs.dealReservesData.label',
    order: 12,
    showIf: {
      dealReserves: {
        value: true,
      },
    },
  },
  strategyBasicPosition: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:exchangeOptions.inputs.strategyBasicPosition.label',
    tooltip:
      'forms_assets:exchangeOptions.inputs.strategyBasicPosition.tooltip',
    order: 14,
    items: StrategyBasicPositionList,
  },
  strategyDescription: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:exchangeOptions.inputs.strategyDescription.label',
    order: 15,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const ExchangeOptionsForm: ExchangeOptionsFormType = [
  ExchangeOptionsFormPart1,
  ExchangeOptionsFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgent',
    'taxAgentOther',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { ExchangeOptionsForm };
