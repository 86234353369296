import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { PersonalIdentifierType } from '../../sj-orm/models/document/personal-identifier.document.dto';

export enum JewelryType {
  ring = 'ring',
  earrings = 'earrings',
  necklace = 'necklace',
  pendant = 'pendant',
  bracelet = 'bracelet',
  brooch = 'brooch',
  cufflinks = 'cufflinks',
  clasp = 'clasp',
  tiara = 'tiara',
  other = 'other',
}

const JewelryList = [
  {
    label: 'forms_assets:jewelery.enums.type.ring',
    key: JewelryType.ring,
  },
  {
    label: 'forms_assets:jewelery.enums.type.earrings',
    key: JewelryType.earrings,
  },
  {
    label: 'forms_assets:jewelery.enums.type.necklace',
    key: JewelryType.necklace,
  },
  {
    label: 'forms_assets:jewelery.enums.type.pendant',
    key: JewelryType.pendant,
  },
  {
    label: 'forms_assets:jewelery.enums.type.bracelet',
    key: JewelryType.bracelet,
  },
  {
    label: 'forms_assets:jewelery.enums.type.brooch',
    key: JewelryType.brooch,
  },
  {
    label: 'forms_assets:jewelery.enums.type.cufflinks',
    key: JewelryType.cufflinks,
  },
  {
    label: 'forms_assets:jewelery.enums.type.clasp',
    key: JewelryType.clasp,
  },
  {
    label: 'forms_assets:jewelery.enums.type.tiara',
    key: JewelryType.tiara,
  },
  {
    label: 'forms_assets:jewelery.enums.type.other',
    key: JewelryType.other,
  },
];

export enum JewelryTypeName {
  brand = 'brand',
  relic = 'relic',
  customOrder = 'customOrder',
  vintage = 'vintage',
}

const JewelryTypeList = [
  {
    label: 'forms_assets:jewelery.enums.jeweleryType.brand',
    key: JewelryTypeName.brand,
  },
  {
    label: 'forms_assets:jewelery.enums.jeweleryType.relic',
    key: JewelryTypeName.relic,
  },
  {
    label: 'forms_assets:jewelery.enums.jeweleryType.customOrder',
    key: JewelryTypeName.customOrder,
  },
  {
    label: 'forms_assets:jewelery.enums.jeweleryType.vintage',
    key: JewelryTypeName.vintage,
  },
];

export enum MetalType {
  whiteGold = 'whiteGold',
  yellowGold = 'yellowGold',
  roseGold = 'roseGold',
  silver = 'silver',
  platinum = 'platinum',
  steel = 'steel',
  titanium = 'titanium',
  ceramic = 'ceramic',
  other = 'other',
}

const MetalTypeList = [
  {
    label: 'forms_assets:jewelery.enums.metalType.whiteGold',
    key: MetalType.whiteGold,
  },
  {
    label: 'forms_assets:jewelery.enums.metalType.yellowGold',
    key: MetalType.yellowGold,
  },
  {
    label: 'forms_assets:jewelery.enums.metalType.roseGold',
    key: MetalType.roseGold,
  },
  {
    label: 'forms_assets:jewelery.enums.metalType.silver',
    key: MetalType.silver,
  },
  {
    label: 'forms_assets:jewelery.enums.metalType.platinum',
    key: MetalType.platinum,
  },
  {
    label: 'forms_assets:jewelery.enums.metalType.steel',
    key: MetalType.steel,
  },
  {
    label: 'forms_assets:jewelery.enums.metalType.titanium',
    key: MetalType.titanium,
  },
  {
    label: 'forms_assets:jewelery.enums.metalType.ceramic',
    key: MetalType.ceramic,
  },
  {
    label: 'forms_assets:jewelery.enums.metalType.other',
    key: MetalType.other,
  },
];

export enum PreciousGemstonesType {
  no = 'no',
  diamond = 'diamond',
  emerald = 'emerald',
  ruby = 'ruby',
  sapphire = 'sapphire',
  alexandrite = 'alexandrite',
  naturalPearl = 'naturalPearl',
  spinel = 'spinel',
  other = 'other',
}

const PreciousGemstonesList = [
  {
    label: 'forms_assets:jewelery.enums.preciousGemstones.no',
    key: PreciousGemstonesType.no,
  },
  {
    label: 'forms_assets:jewelery.enums.preciousGemstones.diamond',
    key: PreciousGemstonesType.diamond,
  },
  {
    label: 'forms_assets:jewelery.enums.preciousGemstones.emerald',
    key: PreciousGemstonesType.emerald,
  },
  {
    label: 'forms_assets:jewelery.enums.preciousGemstones.ruby',
    key: PreciousGemstonesType.ruby,
  },
  {
    label: 'forms_assets:jewelery.enums.preciousGemstones.sapphire',
    key: PreciousGemstonesType.sapphire,
  },
  {
    label: 'forms_assets:jewelery.enums.preciousGemstones.alexandrite',
    key: PreciousGemstonesType.alexandrite,
  },
  {
    label: 'forms_assets:jewelery.enums.preciousGemstones.naturalPearl',
    key: PreciousGemstonesType.naturalPearl,
  },
  {
    label: 'forms_assets:jewelery.enums.preciousGemstones.spinel',
    key: PreciousGemstonesType.spinel,
  },
  {
    label: 'forms_assets:jewelery.enums.preciousGemstones.other',
    key: PreciousGemstonesType.other,
  },
];

export enum ItemConditionType {
  brandNew = 'brandNew',
  used = 'used',
}

const ItemConditionList = [
  {
    label: 'forms_assets:jewelery.enums.itemCondition.brandNew',
    key: ItemConditionType.brandNew,
  },
  {
    label: 'forms_assets:jewelery.enums.itemCondition.used',
    key: ItemConditionType.used,
  },
];

export enum TypeOfHistoryOfOriginType {
  gift = 'gift',
  purchase = 'purchase',
  inheritance = 'inheritance',
  other = 'other',
}

const TypeOfHistoryOfOriginList = [
  {
    label: 'forms_assets:gemstones.enums.typeOfHistoryOfOrigin.gift',
    key: TypeOfHistoryOfOriginType.gift,
  },
  {
    label: 'forms_assets:gemstones.enums.typeOfHistoryOfOrigin.purchase',
    key: TypeOfHistoryOfOriginType.purchase,
  },
  {
    label: 'forms_assets:gemstones.enums.typeOfHistoryOfOrigin.inheritance',
    key: TypeOfHistoryOfOriginType.inheritance,
  },
  {
    label: 'forms_assets:gemstones.enums.typeOfHistoryOfOrigin.other',
    key: TypeOfHistoryOfOriginType.other,
  },
];

type JewelryPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type: JewelryType;
  otherType: string;
  purchaseValue: number;
  kind: JewelryTypeName;
  brand: string;
  storageLocation: string;
};

type JewelryPart2 = Omit<BaseDto, 'id'> & {
  collection?: boolean;
  collectionName?: string;
  purchaseAmount?: number;
  independentAppraisalAmount?: number;
  metal?: MetalType;
  otherMetal?: string;
  preciousGemstones?: PreciousGemstonesType;
  otherPreciousGemstones?: string;
  preciousGemstonesWeight?: number;
  preciousGemstonesColor?: string;
  serialNumber?: string;
  acquisitionDate?: number;
  receipt?: string;
  paymentMethod?: string;
  purchaseLocation?: string;
  registrationInBrandDatabase?: boolean;
  phoneNumber?: string;
  email?: string;
  personalInformation?: DtoId[];
  managerSellerContact?: string;
  packagingAvailability?: boolean;
  itemCondition?: ItemConditionType;
  defects?: string;
  historyOfOrigin?: FormLabelItem;
  typeOfHistoryOfOrigin?: TypeOfHistoryOfOriginType;
  otherTypeOfHistoryOfOrigin?: string;
  occasion?: string;
  source?: string;
  photo?: string;
  insurance: boolean;
  insuranceDocument: DtoId[];
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type JewelryHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type: QuestionType.SELECT_FROM_LIST;
  otherType: QuestionType.TEXT;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  currency: QuestionType.SELECT_FROM_LIST;
  kind: QuestionType.SELECT_FROM_LIST;
  brand: QuestionType.TEXT;
  storageLocation: QuestionType.TEXT;
};

type JewelryHelpPart2 = {
  collection?: QuestionType.CHECKBOX;
  collectionName?: QuestionType.TEXT;
  purchaseAmount?: QuestionType.CURRENCY_CALCULATOR;
  independentAppraisalAmount?: QuestionType.CURRENCY_CALCULATOR;
  metal?: QuestionType.SELECT_FROM_LIST;
  otherMetal?: QuestionType.TEXT;
  preciousGemstones?: QuestionType.SELECT_FROM_LIST;
  otherPreciousGemstones?: QuestionType.TEXT;
  preciousGemstonesWeight?: QuestionType.NUMBER;
  preciousGemstonesColor?: QuestionType.TEXT;
  serialNumber?: QuestionType.TEXT;
  acquisitionDate?: QuestionType.DATE_TIME;
  receipt?: QuestionType.FILE_UPLOAD;
  paymentMethod?: QuestionType.TEXT;
  purchaseLocation?: QuestionType.TEXT;
  registrationInBrandDatabase?: QuestionType.CHECKBOX;
  phoneNumber?: QuestionType.PHONE_NUMBER;
  email?: QuestionType.EMAIL;
  personalInformation?: QuestionType.PICK_ARRAY_OF_DTO;
  managerSellerContact?: QuestionType.TEXT;
  packagingAvailability?: QuestionType.CHECKBOX;
  itemCondition?: QuestionType.SELECT_FROM_LIST;
  defects?: QuestionType.TEXT;
  typeOfHistoryOfOrigin?: QuestionType.SELECT_FROM_LIST;
  otherTypeOfHistoryOfOrigin?: QuestionType.TEXT;
  occasion?: QuestionType.TEXT;
  source?: QuestionType.TEXT;
  photo?: QuestionType.FILE_UPLOAD;
  insurance: QuestionType.CHECKBOX;
  insuranceDocument: QuestionType.PICK_ARRAY_OF_DTO;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type JewelryFormType = [
  JewelryFormPart1Type,
  JewelryFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type JewelryFormPart1Type = TDtoWrapper<JewelryPart1, JewelryHelpPart1>;
type JewelryFormPart2Type = TDtoWrapper<JewelryPart2, JewelryHelpPart2>;

const JewelryFormPart1: JewelryFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:jewelery.inputs.countryOfAcquisition.label',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:jewelery.inputs.type.label',
    value: JewelryType.ring,
    required: true,
    items: JewelryList,
  },
  otherType: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.otherType.label',
    showIf: {
      type: {
        value: JewelryType.other,
      },
    },
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR, //
    label: 'forms_assets:jewelery.inputs.personalAppraisal.label',
    tooltip: 'forms_assets:jewelery.inputs.personalAppraisal.tooltip',
    equivalentValueTooltip:
      // eslint-disable-next-line sonarjs/no-duplicate-string
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  kind: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:jewelery.inputs.kind.label',
    value: JewelryTypeName.brand,
    // required: true,
    items: JewelryTypeList,
  },
  brand: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.brand.label',
    value: '',
    // required: true,
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.storageLocation.label',
    value: '',
    // required: true,
  },
};

const JewelryFormPart2: JewelryFormPart2Type = {
  purchaseAmount: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:jewelery.inputs.purchaseAmount.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  independentAppraisalAmount: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:jewelery.inputs.independentAppraisalAmount.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 2,
  },
  collection: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:jewelery.inputs.collection.label',
    order: 3,
  },
  collectionName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.collectionName.label',
    order: 4,
    showIf: {
      collection: {
        value: true,
      },
    },
  },
  metal: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:jewelery.inputs.metal.label',
    items: MetalTypeList,
    order: 5,
    mode: 'multiple',
  },
  otherMetal: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.otherMetal.label',
    order: 5,
    showIf: {
      metal: {
        value: MetalType.other,
      },
    },
  },
  preciousGemstones: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:jewelery.inputs.preciousGemstones.label',
    order: 6,
    items: PreciousGemstonesList,
  },
  otherPreciousGemstones: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.otherPreciousGemstones.label',
    order: 7,
    showIf: {
      preciousGemstones: {
        value: PreciousGemstonesType.other,
      },
    },
  },
  preciousGemstonesWeight: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:jewelery.inputs.preciousGemstonesWeight.label',
    order: 8,
  },
  preciousGemstonesColor: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.preciousGemstonesColor.label',
    order: 9,
  },
  serialNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.serialNumber.label',
    order: 10,
  },
  acquisitionDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:jewelery.inputs.acquisitionDate.label',
    order: 11,
  },
  receipt: {
    type: QuestionType.FILE_UPLOAD,
    label: 'forms_assets:jewelery.inputs.purchaseReceipt.label',
    order: 12,
  },
  paymentMethod: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.paymentMethod.label',
    order: 13,
  },
  purchaseLocation: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.purchaseLocation.label',
    order: 14,
  },
  registrationInBrandDatabase: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:jewelery.inputs.registrationInBrandDatabase.label',
    order: 15,
  },
  phoneNumber: {
    type: QuestionType.PHONE_NUMBER,
    label: 'forms_assets:common.inputs.phoneNumber.label',
    order: 16,
    showIf: {
      registrationInBrandDatabase: {
        value: true,
      },
    },
  },
  email: {
    type: QuestionType.EMAIL,
    label: 'forms_assets:common.inputs.email.label',
    order: 17,
    showIf: {
      registrationInBrandDatabase: {
        value: true,
      },
    },
  },
  personalInformation: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:jewelery.inputs.personalInformation.label',
    order: 18,
    items: 'personalIdentifiersDocuments',
    showIf: {
      registrationInBrandDatabase: {
        value: true,
      },
    },
    dtoType: PersonalIdentifierType.BIRTH_CERTIFICATE,
  },
  managerSellerContact: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.managerSellerContact.label',
    order: 19,
    showIf: {
      registrationInBrandDatabase: {
        value: true,
      },
    },
  },
  packagingAvailability: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:jewelery.inputs.packagingAvailability.label',
    order: 20,
  },
  itemCondition: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:jewelery.inputs.itemCondition.label',
    order: 21,
    items: ItemConditionList,
  },
  defects: {
    type: QuestionType.TEXT,
    label: 'forms_assets:jewelery.inputs.defects.label',
    order: 22,
  },
  insurance: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:jewelery.inputs.insurance.label',
    order: 23,
  },
  insuranceDocument: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:jewelery.inputs.insuranceDocument.label',
    order: 24,
    items: 'privateDocuments',
    showIf: {
      insurance: {
        value: true,
      },
    },
    dtoType: PrivateDocumentCategory.OTHER,
  },
  photo: {
    type: QuestionType.FILE_UPLOAD,
    label: 'forms_assets:jewelery.inputs.photo.label',
    tooltip: 'forms_assets:jewelery.inputs.photo.tooltip',
    order: 25,
  },
  historyOfOrigin: {
    type: FormLabelItem.label,
    label: 'forms_assets:gemstones.inputs.historyOfOrigin.label',
    order: 26,
  },
  typeOfHistoryOfOrigin: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:gemstones.inputs.typeOfHistoryOfOrigin.label',
    order: 27,
    items: TypeOfHistoryOfOriginList,
  },
  otherTypeOfHistoryOfOrigin: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.otherTypeOfHistoryOfOrigin.label',
    order: 28,
    showIf: {
      typeOfHistoryOfOrigin: {
        value: TypeOfHistoryOfOriginType.other,
      },
    },
  },
  occasion: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.occasion.label',
    tooltip: 'forms_assets:gemstones.inputs.occasion.tooltip',
    order: 29,
  },
  source: {
    type: QuestionType.TEXT,
    label: 'forms_assets:gemstones.inputs.source.label',
    tooltip: 'forms_assets:gemstones.inputs.source.tooltip',
    order: 30,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const JewelryForm: JewelryFormType = [
  JewelryFormPart1,
  JewelryFormPart2,
  assetsOwnershipBuilder([
    'contactInfoLabel',
    'contactInfoLabelDescription',
    'address',
    'phoneNumber',
    'email',
  ]).form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgent',
    'publicFundTaxAgent',
    'taxAgentOther',
    'taxAgentWithBrokerOther',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { JewelryForm };
