const baseUrl = process.env.NEXT_PUBLIC_SELF_URL_BASE;
const paUrl = process.env.NEXT_PUBLIC_PA_URL;
const redirectUrl = `${baseUrl}/?authGrant={authGrant}`;
export const isExported = process.env.NEXT_PUBLIC_APP_MODE === 'EXPORTED';

export const SSO_AUTH_URL = `${paUrl}/en/oauth/signin?redirect_url=${encodeURIComponent(
  redirectUrl,
)}`;

export const AUTH_STORAGE_KEY = 'sso_auth_token_key';

export const IS_READ_INSTRUCTIONS_WHAT_DIFFERENCE =
  'is_read_instructions_what_difference';
