import { create } from 'zustand';

import { IPhraseWord } from '@/widgets/mnemonic-phrase/confirm-mnemonic-phrase/types';

type IMnemonicPhraseState = {
  currentWord: number;
  fullPhrase: IPhraseWord[];
  phraseEntered: IPhraseWord[];
  phraseLeft: IPhraseWord[];
  cellsFilled: number[];
  inputPhrase: string;
  mnemonicPhrase: string[];
  isDisclaimerRead: boolean;
  setIsDisclaimerRead: (is: boolean) => void;
  setMnemonicPhrase: (phrase: string[]) => void;
  cleanMnemonicPhrase: () => void;
  setCurrentWord: (index: number) => void;
  setFullPhrase: (
    index: number,
    item: string,
    isEntered: boolean,
    id: string,
  ) => void;
  setPhraseEntered: (
    index: number,
    item: string,
    isEntered: boolean,
    id: string,
  ) => void;
  setPhraseLeft: (item: string, isEntered: boolean, id: string) => void;
  deleteWordPhraseEntered: (index: number) => void;
  deleteWordPhraseLeft: (wordId: string) => void;
  setCellsFilled: (number: number) => void;
  deleteCellsFilled: (number: number) => void;
  setInputPhrase: (phrase: string) => void;
  deleteInputPhrase: () => void;
};

export const useMnemonicPhraseStore = create<IMnemonicPhraseState>((set) => ({
  currentWord: 0,
  fullPhrase: [],
  phraseEntered: [],
  phraseLeft: [],
  cellsFilled: [],
  inputPhrase: '',
  mnemonicPhrase: [],
  isDisclaimerRead: false,
  setMnemonicPhrase: (phrase) => set(() => ({ mnemonicPhrase: phrase })),
  cleanMnemonicPhrase: () => set(() => ({ mnemonicPhrase: [] })),
  setCurrentWord: (index) => set(() => ({ currentWord: index })),
  setFullPhrase: (
    index: number,
    item: string,
    isEntered: boolean,
    id: string,
  ): void =>
    set((state) => ({
      fullPhrase: [
        ...state.fullPhrase,
        {
          number: index,
          word: item,
          isEntered: isEntered,
          id,
        },
      ],
    })),
  setPhraseEntered: (
    index: number,
    item: string,
    isEntered: boolean,
    id,
  ): void =>
    set((state) => ({
      phraseEntered: [
        ...state.phraseEntered,
        { number: index, word: item, isEntered: isEntered, id } as IPhraseWord,
      ],
    })),
  setPhraseLeft: (item: string, isEntered: boolean, id: string): void =>
    set((state) => ({
      phraseLeft: [
        ...state.phraseLeft,
        { word: item, isEntered: isEntered, id } as IPhraseWord,
      ],
    })),
  deleteWordPhraseEntered: (index: number): void => {
    set((state) => ({
      phraseEntered: state.phraseEntered.filter(
        (item) => item.number !== index,
      ),
    }));
  },
  deleteWordPhraseLeft: (wordId: string): void => {
    set((state) => ({
      phraseLeft: state.phraseLeft.filter((item) => item.id !== wordId),
    }));
  },
  setCellsFilled: (number: number): void => {
    set((state) => ({
      cellsFilled: [...state.cellsFilled, number].sort((a, b) => a - b),
    }));
  },
  deleteCellsFilled: (number: number): void => {
    set((state) => ({
      cellsFilled: state.cellsFilled.filter((item) => item !== number),
    }));
  },
  setInputPhrase: (phrase: string): void =>
    set(() => ({
      inputPhrase: phrase,
    })),
  deleteInputPhrase: (): void => {
    set(() => ({
      inputPhrase: '',
    }));
  },
  setIsDisclaimerRead: (is) => {
    set(() => ({ isDisclaimerRead: is }));
  },
}));
