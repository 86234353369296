import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export type BankCell = BankCellPart1Type &
  BankCellPart2Type &
  AssetsOwnershipPartType &
  AssetsStatisticsPartType;

export enum BankCellType {
  standard = 'standard',
  safekeeping = 'safekeeping',
}

const BankCellList = [
  {
    key: BankCellType.standard,
    label: 'forms_assets:bankCells.enums.type.standard',
  },
  {
    key: BankCellType.safekeeping,
    label: 'forms_assets:bankCells.enums.type.safekeeping',
  },
];

export enum BankCellAccessMethod {
  keys = 'keys',
  code = 'code',
  fingerprint = 'fingerprint',
  retina = 'retina',
}

const BankCellAccessMethodsList = [
  {
    key: BankCellAccessMethod.keys,
    label: 'forms_assets:bankCells.enums.accessMethod.keys',
  },
  {
    key: BankCellAccessMethod.code,
    label: 'forms_assets:bankCells.enums.accessMethod.code',
  },
  {
    key: BankCellAccessMethod.fingerprint,
    label: 'forms_assets:bankCells.enums.accessMethod.fingerprint',
  },
  {
    key: BankCellAccessMethod.retina,
    label: 'forms_assets:bankCells.enums.accessMethod.retina',
  },
];

type BankCellPart1Type = Omit<BaseDto, 'id'> & {
  nickname: string;
  country: string;
  type: BankCellType;
  purchaseValue: number; //curr
  isCashStorage: boolean;
  isOtherValuables: boolean;
  otherValuables: string;
  documents: DtoId[];
  isDocuments: boolean;
  bank: DtoId[];
  accessMethod: BankCellAccessMethod;
  keyStorageLocation: string;
};

type BankCellPart2Type = Omit<BaseDto, 'id'> & {
  depositBoxAddress?: string;
  depositBoxNumber?: string;
  depositBoxSize?: string;
  openingDate?: Date;
  validUntil?: Date;
  accessMethod?: BankCellAccessMethod;
  keyStorageLocation?: string;
  specialAccessConditions?: boolean;
  specialAccessConditionsComment?: string;
  depositBoxAgreement?: DtoId[];
  insuranceAgreement?: DtoId[];
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: DtoId[];
};

type BankCellHelpPart1Type = {
  nickname: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  isCashStorage: QuestionType.CHECKBOX;
  isOtherValuables: QuestionType.CHECKBOX;
  otherValuables: QuestionType.TEXT;
  bank: QuestionType.PICK_ARRAY_OF_DTO;
  documents: QuestionType.PICK_ARRAY_OF_DTO;
  isDocuments: QuestionType.CHECKBOX;
  accessMethod: QuestionType.SELECT_FROM_LIST;
  keyStorageLocation: QuestionType.TEXT;
};

type BankCellHelpPart2Type = {
  documents?: QuestionType.PICK_ARRAY_OF_DTO;
  depositBoxAddress?: QuestionType.TEXT;
  depositBoxNumber?: QuestionType.TEXT;
  depositBoxSize?: QuestionType.TEXT;
  openingDate?: QuestionType.DATE_TIME;
  validUntil?: QuestionType.DATE_TIME;
  accessMethod?: QuestionType.SELECT_FROM_LIST;
  keyStorageLocation?: QuestionType.TEXT;
  specialAccessConditions?: QuestionType.CHECKBOX;
  specialAccessConditionsComment?: QuestionType.TEXT_MULTILINE;
  depositBoxAgreement?: QuestionType.PICK_ARRAY_OF_DTO;
  insuranceAgreement?: QuestionType.PICK_ARRAY_OF_DTO;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type BankCellFormPart1Type = TDtoWrapper<
  BankCellPart1Type,
  BankCellHelpPart1Type
>;
type BankCellFormPart2Type = TDtoWrapper<
  BankCellPart2Type,
  BankCellHelpPart2Type
>;

export type BankCellFormType = [
  BankCellFormPart1Type,
  BankCellFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

const BankCellFormPart1: BankCellFormPart1Type = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:bankCells.inputs.type.label',
    value: BankCellType.standard,
    required: true,
    items: BankCellList,
  },
  isCashStorage: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:bankCells.inputs.isCashStorage.label',
    tooltip: 'forms_assets:bankCells.inputs.isCashStorage.tooltip',
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:bankCells.inputs.amountInBaseCurrency.label',
    tooltip: 'forms_assets:bankCells.inputs.amountInBaseCurrency.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  bank: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:bankCells.inputs.bank.label',
    items: 'contacts',
    value: [''],
    // required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:bankCells.inputs.bank.errors.required'),
    tooltip: 'forms_assets:bankCells.inputs.bank.tooltip',
    filter: (contact) =>
      (contact as unknown as ContactDto).type === ContactType.BANK,
    dtoType: ContactType.BANK,
  },
  isDocuments: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:bankCells.inputs.isDocuments.label',
  },
  documents: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:bankCells.inputs.documents.label',
    items: 'privateDocuments',
    mode: 'multiple',
    required: true,
    value: [],
    showIf: {
      isDocuments: {
        value: true,
      },
    },
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  isOtherValuables: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:bankCells.inputs.isOtherValuables.label',
  },
  otherValuables: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bankCells.inputs.otherValuables.label',
    yupScheme: yup
      .string()
      .required('forms_assets:bankCells.inputs.otherValuables.errors.required'),
    showIf: {
      isOtherValuables: {
        value: true,
      },
    },
  },
  accessMethod: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:bankCells.inputs.accessMethod.label',
    items: BankCellAccessMethodsList,
    value: BankCellAccessMethod.code,
  },
  keyStorageLocation: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bankCells.inputs.keyStorageLocation.label',
    tooltip: 'forms_assets:bankCells.inputs.keyStorageLocation.tooltip',
    required: true,
    value: '',
    showIf: {
      accessMethod: {
        value: BankCellAccessMethod.keys,
      },
    },
  },
};

const BankCellFormPart2: BankCellFormPart2Type = {
  depositBoxAddress: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bankCells.inputs.depositBoxAddress.label',
    order: 1,
  },
  depositBoxNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bankCells.inputs.depositBoxNumber.label',
    order: 2,
  },
  depositBoxSize: {
    type: QuestionType.TEXT,
    label: 'forms_assets:bankCells.inputs.depositBoxSize.label',
    order: 3,
  },
  openingDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:bankCells.inputs.openingDate.label',
    order: 4,
  },
  validUntil: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:bankCells.inputs.validUntil.label',
    order: 5,
    tooltip: 'forms_assets:bankCells.inputs.validUntil.tooltip',
  },
  specialAccessConditions: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:bankCells.inputs.specialAccessConditions.label',
    order: 6,
  },
  specialAccessConditionsComment: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:bankCells.inputs.specialAccessConditionsComment.label',
    showIf: {
      specialAccessConditions: {
        value: true,
      },
    },
    order: 7,
  },
  depositBoxAgreement: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:bankCells.inputs.depositBoxAgreement.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  insuranceAgreement: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:bankCells.inputs.insuranceAgreement.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.PUBLIC_AGREEMENTS,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

//todo rename to deposit box
const BankCellForm: BankCellFormType = [
  BankCellFormPart1,
  BankCellFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgent',
    'publicFundTaxAgent',
    'taxAgentOther',
    'taxAgentWithBrokerOther',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { BankCellForm };
