import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

// export enum CentralizedWalletType {
//   algorithmicCurrencies = 'algorithmicCurrencies',
//   stableCoins = 'stableCoins',
// }
//
// const CentralizedWalletTypesList = [
//   {
//     label: 'forms_assets:centralizedWallet.enums.type.algorithmicCurrencies',
//     key: CentralizedWalletType.algorithmicCurrencies,
//   },
//   {
//     label: 'forms_assets:centralizedWallet.enums.type.stableCoins',
//     key: CentralizedWalletType.stableCoins,
//   },
// ];
export enum CurrenciesType {
  BTC = 'BTC',
  ETH = 'ETH',
  USDT = 'USDT',
  BNB = 'BNB',
  SOL = 'SOL',
  XRP = 'XRP',
  USDC = 'USDC',
  ADA = 'ADA',
  AVAX = 'AVAX',
  DOGE = 'DOGE',
  DOT = 'DOT',
  TRX = 'TRX',
  LINK = 'LINK',
  MATIC = 'MATIC',
  WBTC = 'WBTC',
  SHIB = 'SHIB',
  LTC = 'LTC',
  DAI = 'DAI',
  BCH = 'BCH',
  ICP = 'ICP',
  UNI = 'UNI',
  XLM = 'XLM',
  LEO = 'LEO',
  OKB = 'OKB',
  XMR = 'XMR',
  ETC = 'ETC',
  ATOM = 'ATOM',
  CRO = 'CRO',
  FIL = 'FIL',
  TUSD = 'TUSD',
  NEAR = 'NEAR',
  BTCB = 'BTCB',
  VET = 'VET',
  INJ = 'INJ',
  RUNE = 'RUNE',
  LDO = 'LDO',
  RNDR = 'RNDR',
  ALGO = 'ALGO',
  AAVE = 'AAVE',
  GRT = 'GRT',
  EGLD = 'EGLD',
  STX = 'STX',
  QNT = 'QNT',
  FTT = 'FTT',
  HNT = 'HNT',
  WEMIX = 'WEMIX',
  HBAR = 'HBAR',
  MKR = 'MKR',
  BUSD = 'BUSD',
  FTM = 'FTM',
  FLOW = 'FLOW',
  THETA = 'THETA',
  SAND = 'SAND',
  KCS = 'KCS',
  BSV = 'BSV',
  SNX = 'SNX',
  AXS = 'AXS',
  MANA = 'MANA',
  NEO = 'NEO',
  IOTA = 'IOTA',
  XTZ = 'XTZ',
  EOS = 'EOS',
  KAVA = 'KAVA',
  GALA = 'GALA',
  KLAY = 'KLAY',
  MINA = 'MINA',
  WOO = 'WOO',
  ar = 'ar',
  CFX = 'CFX',
  XEC = 'XEC',
  FXS = 'FXS',
  CHZ = 'CHZ',
  ROSE = 'ROSE',
  XDC = 'XDC',
  CAKE = 'CAKE',
  CRV = 'CRV',
  GNO = 'GNO',
  DYDX = 'DYDX',
  ELF = 'ELF',
  FET = 'FET',
  RPL = 'RPL',
  gt = 'gt',
  AKT = 'AKT',
  ZEC = 'ZEC',
  TWT = 'TWT',
  CSPR = 'CSPR',
  GAS = 'GAS',
  NEXO = 'NEXO',
  ht = 'ht',
  COMP = 'COMP',
  OneINCH = '1INCH',
  ZIL = 'ZIL',
  AGIX = 'AGIX',
  IOTX = 'IOTX',
  sc = 'sc',
  STORJ = 'STORJ',
  DASH = 'DASH',
  HOT = 'HOT',
  USDP = 'USDP',
  ILV = 'ILV',
}

export const CurrenciesList = [
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.BTC',
    key: CurrenciesType.BTC,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ETH',
    key: CurrenciesType.ETH,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.USDT',
    key: CurrenciesType.USDT,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.USDC',
    key: CurrenciesType.USDC,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.1INCH',
    key: CurrenciesType.OneINCH,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.AAVE',
    key: CurrenciesType.AAVE,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ELF',
    key: CurrenciesType.ELF,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.AKT',
    key: CurrenciesType.AKT,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ALGO',
    key: CurrenciesType.ALGO,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ar',
    key: CurrenciesType.ar,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.AVAX',
    key: CurrenciesType.AVAX,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.AXS',
    key: CurrenciesType.AXS,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.BTCB',
    key: CurrenciesType.BTCB,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.BCH',
    key: CurrenciesType.BCH,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.BSV',
    key: CurrenciesType.BSV,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.BNB',
    key: CurrenciesType.BNB,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.BUSD',
    key: CurrenciesType.BUSD,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ADA',
    key: CurrenciesType.ADA,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.CSPR',
    key: CurrenciesType.CSPR,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.LINK',
    key: CurrenciesType.LINK,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.CHZ',
    key: CurrenciesType.CHZ,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.COMP',
    key: CurrenciesType.COMP,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.CFX',
    key: CurrenciesType.CFX,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ATOM',
    key: CurrenciesType.ATOM,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.CRO',
    key: CurrenciesType.CRO,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.CRV',
    key: CurrenciesType.CRV,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.DASH',
    key: CurrenciesType.DASH,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.MANA',
    key: CurrenciesType.MANA,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.DOGE',
    key: CurrenciesType.DOGE,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.DYDX',
    key: CurrenciesType.DYDX,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.XEC',
    key: CurrenciesType.XEC,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.EOS',
    key: CurrenciesType.EOS,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ETC',
    key: CurrenciesType.ETC,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.FTM',
    key: CurrenciesType.FTM,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.FET',
    key: CurrenciesType.FET,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.FIL',
    key: CurrenciesType.FIL,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.FLOW',
    key: CurrenciesType.FLOW,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.FXS',
    key: CurrenciesType.FXS,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.FTT',
    key: CurrenciesType.FTT,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.GALA',
    key: CurrenciesType.GALA,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.GAS',
    key: CurrenciesType.GAS,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.gt',
    key: CurrenciesType.gt,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.GNO',
    key: CurrenciesType.GNO,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.HBAR',
    key: CurrenciesType.HBAR,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.HNT',
    key: CurrenciesType.HNT,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.HOT',
    key: CurrenciesType.HOT,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ht',
    key: CurrenciesType.ht,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ILV',
    key: CurrenciesType.ILV,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.INJ',
    key: CurrenciesType.INJ,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ICP',
    key: CurrenciesType.ICP,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.IOTA',
    key: CurrenciesType.IOTA,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.IOTX',
    key: CurrenciesType.IOTX,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.KAVA',
    key: CurrenciesType.KAVA,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.KLAY',
    key: CurrenciesType.KLAY,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.KCS',
    key: CurrenciesType.KCS,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.LDO',
    key: CurrenciesType.LDO,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.LTC',
    key: CurrenciesType.LTC,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.MKR',
    key: CurrenciesType.MKR,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.MINA',
    key: CurrenciesType.MINA,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.XMR',
    key: CurrenciesType.XMR,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.DAI',
    key: CurrenciesType.DAI,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.EGLD',
    key: CurrenciesType.EGLD,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.NEAR',
    key: CurrenciesType.NEAR,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.NEO',
    key: CurrenciesType.NEO,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.NEXO',
    key: CurrenciesType.NEXO,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ROSE',
    key: CurrenciesType.ROSE,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.OKB',
    key: CurrenciesType.OKB,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.CAKE',
    key: CurrenciesType.CAKE,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.USDP',
    key: CurrenciesType.USDP,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.DOT',
    key: CurrenciesType.DOT,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.MATIC',
    key: CurrenciesType.MATIC,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.QNT',
    key: CurrenciesType.QNT,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.RNDR',
    key: CurrenciesType.RNDR,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.RPL',
    key: CurrenciesType.RPL,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.SHIB',
    key: CurrenciesType.SHIB,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.sc',
    key: CurrenciesType.sc,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.AGIX',
    key: CurrenciesType.AGIX,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.SOL',
    key: CurrenciesType.SOL,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.STX',
    key: CurrenciesType.STX,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.XLM',
    key: CurrenciesType.XLM,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.STORJ',
    key: CurrenciesType.STORJ,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.SNX',
    key: CurrenciesType.SNX,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.XTZ',
    key: CurrenciesType.XTZ,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.GRT',
    key: CurrenciesType.GRT,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.SAND',
    key: CurrenciesType.SAND,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.THETA',
    key: CurrenciesType.THETA,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.RUNE',
    key: CurrenciesType.RUNE,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.TRX',
    key: CurrenciesType.TRX,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.TUSD',
    key: CurrenciesType.TUSD,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.TWT',
    key: CurrenciesType.TWT,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.UNI',
    key: CurrenciesType.UNI,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.LEO',
    key: CurrenciesType.LEO,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.VET',
    key: CurrenciesType.VET,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.WEMIX',
    key: CurrenciesType.WEMIX,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.WOO',
    key: CurrenciesType.WOO,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.WBTC',
    key: CurrenciesType.WBTC,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.XDC',
    key: CurrenciesType.XDC,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.XRP',
    key: CurrenciesType.XRP,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ZEC',
    key: CurrenciesType.ZEC,
  },
  {
    label: 'forms_assets:centralizedWallet.enums.assetsInWallet.ZIL',
    key: CurrenciesType.ZIL,
  },
];

type CentralizedWalletPart1Type = Omit<BaseDto, 'id'> & {
  nickname: string;
  country: string;
  assetsInWallet: CurrenciesType;
  purchaseValue: number;
  holder: string;
};

type CentralizedWalletPart2Type = Omit<BaseDto, 'id'> & {
  accountNumber?: string;
  loginStorageLocation?: string;
  kyc?: boolean;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type CentralizedWalletHelpPart1Type = {
  nickname: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  assetsInWallet: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  holder: QuestionType.TEXT;
};

type CentralizedWalletHelpPart2Type = {
  accountNumber?: QuestionType.TEXT;
  loginStorageLocation?: QuestionType.TEXT;
  kyc?: QuestionType.CHECKBOX;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type CentralizedWalletFormPart1Type = TDtoWrapper<
  CentralizedWalletPart1Type,
  CentralizedWalletHelpPart1Type
>;
type CentralizedWalletFormPart2Type = TDtoWrapper<
  CentralizedWalletPart2Type,
  CentralizedWalletHelpPart2Type
>;

type CentralizedWalletFormType = [
  CentralizedWalletFormPart1Type,
  CentralizedWalletFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

const CentralizedWalletFormPart1: CentralizedWalletFormPart1Type = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  assetsInWallet: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:centralizedWallet.inputs.assetsInWallet.label',
    value: CurrenciesType.BTC,
    // required: true,
    items: CurrenciesList,
    mode: 'multiple',
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:centralizedWallet.inputs.purchaseValue.label',
    tooltip: 'forms_assets:centralizedWallet.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  holder: {
    type: QuestionType.TEXT,
    label: 'forms_assets:centralizedWallet.inputs.holder.label',
    tooltip: 'forms_assets:centralizedWallet.inputs.holder.tooltip',
    value: '',
    // required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:centralizedWallet.inputs.holder.errors.required'),
  },
};

const CentralizedWalletFormPart2: CentralizedWalletFormPart2Type = {
  accountNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:centralizedWallet.inputs.accountNumber.label',
    order: 1,
  },
  loginStorageLocation: {
    type: QuestionType.TEXT,
    label: 'forms_assets:centralizedWallet.inputs.loginStorageLocation.label',
    order: 2,
  },
  kyc: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:centralizedWallet.inputs.kyc.label',
    tooltip: 'forms_assets:centralizedWallet.inputs.kyc.tooltip',
    order: 3,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const CentralizedWalletForm: CentralizedWalletFormType = [
  CentralizedWalletFormPart1,
  CentralizedWalletFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgent',
    'taxAgentOther',
    'taxAgentWithBroker',
    'taxAgentWithBrokerOther',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { CentralizedWalletForm };
